import React from 'react'
import SkillsListItem from './SkillsListItem'

const SkillsList = ({dataList,deleteHandler}) => {
    return (
        <div>
            {
                dataList.map((item,index) => (<SkillsListItem key={index} deleteHandler={deleteHandler} index={index} item={item} />))
            }
        </div>
    )
}

export default SkillsList