import React from 'react'
import SkillsTypesListItem from './SkillsTypesListItem'

const SkillsTypesList = ({data,loading,deleteHandler}) => {
    return (
        <div>
            {
                data?.data.map((item,index) => (<SkillsTypesListItem deleteHandler={deleteHandler} key={index} item={item} index={index} />))
            }
        </div>
    )
}

export default SkillsTypesList