import React from 'react'

const DataListViewRow = ({children}) => {
    return (
        <div className='flex gap-2 items-center justify-between bg-slate-100 p-2 border-b hover:bg-slate-200 '>
            {children}
        </div>
    )
}

export default DataListViewRow