import React,{useEffect,useState} from 'react'
import CardHeaderWithActions from '../../components/ui/CardHeaderWithActions'
import { MwButton, MwSpinnerButton } from '../../components/ui'
import { useCurrent, useFetch } from '../../hooks'
import AddNewSkill from '../../components/skills/AddNewSkill'
import SkillsList from '../../components/skills/SkillsList'

const Skills = ({onHide,skillsList,setSkillsList}) => {
    const {currentTenantId,currentCompanyId,currentLangId} = useCurrent()
    const {data:resultData,loading} = useFetch(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/skills-type/index/${currentTenantId}/${currentCompanyId}`)
    
    const [typeSelected,setTypeSelected] = useState({});
    const [skillSelected,setSkillSelected] = useState({});
    const [levelSelected,setLevelSelected] = useState({});

    const [list,setList] = useState(skillsList);

    const saveHandler = (e) => {
        e.preventDefault();
        const _list = list.map(l => ({
            skill_type_id : l.type || l.skill_type_id,
            skill_id: l.skills || l.skill_id,
            skill_level_id:l.levels || l.skill_level_id,
        })) 
        setSkillsList([..._list]);
        console.log('skillsList',skillsList);
        onHide();
    }
    useEffect(() => {
        if(resultData){
            console.log(resultData)
        }
    },[resultData]);

    const deleteHandler = (index) => {
        console.log(index);
        const filterList =  list.filter((item,i) => i !== index )
        setList([...filterList])
    }

    return (
        <div className='flex flex-col gap-3 p-3'>
            <CardHeaderWithActions title={`ادارة المهارات الوظيفية`}>
                <MwButton type='cancelBtn' inGroup={true} onClick={()=>onHide()}>إغلاق</MwButton>
                <MwButton type='saveBtn' inGroup={true} onClick={(e)=>saveHandler(e)}>حفظ</MwButton>
            </CardHeaderWithActions>
            {loading && <MwSpinnerButton isFullCenter={true} />}
            {resultData && <AddNewSkill 
                                dataList={resultData?.data} 
                                typeSelected={typeSelected} 
                                setTypeSelected={setTypeSelected} 
                                currentLangId={currentLangId}
                                skillSelected = {skillSelected}
                                setSkillSelected = {setSkillSelected}
                                levelSelected = {levelSelected}
                                setLevelSelected = {setLevelSelected}
                                list ={list}
                                setList={setList}
                                /> }
            <hr />
            {list.length > 0 && <SkillsList dataList={list} deleteHandler={deleteHandler} /> }
        </div>
    )
}

export default Skills