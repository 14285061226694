import React from 'react'
import { MwInputText } from '../ui'
import AddNewSkill from './AddNewSkill'
import { AddNewLevel } from './AddNewLevel'

const AddNewSkillTypes = ({skillTypeState,setSkillTypeState,errors}) => {

    return (
        <div>
            <div className='w-full'>
                <MwInputText
                    label="نوع المهارة"
                    placeholder="e.g.Languages"
                    value = {skillTypeState.name}
                    onChange = { (e) => setSkillTypeState({ ...skillTypeState,name:e.target.value}) }
                    classNameInput = {`font-bold border border-slate-400`}
                    invalid={!!errors?.name}
                    invalidMsg={errors?.name}
                />
            </div>
            <AddNewSkill skillTypeState={skillTypeState} setSkillTypeState={setSkillTypeState} />
            <AddNewLevel skillTypeState={skillTypeState} setSkillTypeState={setSkillTypeState} />
        </div>
    )
}

export default AddNewSkillTypes