import React from 'react'
import ResumeLinesListItem from '../resume-lines/ResumeLinesListItem'
const ResumeLinesList = ({data,deleteHandler,setMode,setItemSelected}) => {
    return (
        <>
            {
                data?.length > 0 ?
                    <div className='bg-slate-50 rounded-lg text-sm'>
                        <div className='text-sm font-bold text-slate-500 p-2'>
                            <span> قائمة التدريب والخبرات </span>
                        </div>
                        <div className='flex border-b font-bold p-2 text-xs'>
                            <div className='w-16 text-center'>#</div>
                            <div className='flex-1'>اسم التدريب/الخبرة </div>
                            <div className='flex-1'>النوع</div>
                            <div className='flex-1'>تاريخ البداية</div>
                            <div className='flex-1'>تاريخ النهاية</div>
                            <div className='w-20 text-center'>الإجراءات</div>
                        </div>
                        {
                            data.map((item,index) => (<ResumeLinesListItem deleteHandler={deleteHandler} key={index} index={index} setMode={setMode} setItemSelected={setItemSelected} item ={item}/>))
                        }
                    </div>:
                <div></div>
            }
        </>
    )
}

export default ResumeLinesList