import React, { useState } from 'react'
import { MwButton, MwInputText } from '../ui'
import { RiDeleteBin5Line } from "react-icons/ri";

export const AddNewLevel = ({skillTypeState,setSkillTypeState}) => {
    const [levelTitle,setLevelTitle] = useState('')
    const [levelProgress,setLevelProgress] = useState('')
    const [levelList,setLevelList] = useState([]);
    const [errors,setErrors] = useState();

    const saveHandler = () => {
        if(!levelTitle){
            setErrors({title:'يجب ادخال '})
            return;
        }
        setLevelList([...levelList,{title:levelTitle,progress:levelProgress}]);
        setLevelTitle('')
        setLevelProgress('')
        setSkillTypeState({...skillTypeState,levels:[...levelList,{title:levelTitle,progress:levelProgress}]})
        console.log(levelList);
    }

    const deleteHandler = (index) => {
        const _filter = levelList.filter((item,i) => i !== index);
        setLevelList(_filter)
        setSkillTypeState({...skillTypeState,levels:[..._filter]})
    };

    return (
        <div className='flex flex-col'>
        <div className='flex items-end py-3 gap-3'>
            <div className='flex-1'>
                <MwInputText
                    label="المستوي"
                    placeholder=""
                    value = {levelTitle}
                    onChange = { (e) => setLevelTitle(e.target.value) }
                    classNameInput = {`font-bold border border-slate-500`}
                    invalid={!!errors?.title}
                    // invalidMsg={errors?.title}
                />
            </div>
            <div className='flex-1'>
                <MwInputText
                    label="المعدل"
                    inputType="number"
                    placeholder=""
                    value = {levelProgress}
                    onChange = { (e) => setLevelProgress(e.target.value) }
                    classNameInput = {`font-bold border border-slate-500`}
                    invalid={!!errors?.progress}
                    // invalidMsg={errors?.title}
                />
            </div>
            <MwButton type='secondary-reverse'  inGroup={true} onClick={saveHandler}>اضف</MwButton>
        </div>
        <div>
            {
                levelList.map((item,index) =>(
                <div key={index} className='flex justify-between items-center text-slate-500 mx-3 text-sm border-b p-1'>
                    <div className='w-16 text-center'>{index+1}</div>
                    <div className='flex-1'>{item.title}</div>
                    <div className='flex-1  mx-5 '>
                        <div className='w-full bg-slate-300 rounded-lg'>
                            { item.progress && <div className={`bg-slate-400 ${item.progress > 98 ? 'rounded-lg' : 'rounded-r-lg'}  text-center text-slate-50`} style={{ width: item.progress+"%" }}>{item.progress}%</div> }
                        </div>
                    </div>
                    <div onClick={()=>deleteHandler(index)}><RiDeleteBin5Line/></div>
                </div>
                ))
            }
        </div>

    </div>
    )
}
