import React, { useEffect, useState } from 'react'
import { useCurrent,useDelete,useFetch,usePost, usePut } from '../hooks';
import CardHeaderWithActions from '../components/ui/CardHeaderWithActions';
import { MwButton, MwSpinnerButton } from '../components/ui';
import AlertMsg from '../components/ui/AlertMsg';
import AddNewTimeZone from '../components/times-zone/AddNewTimeZone';
import TimeZoneList from '../components/times-zone/TimeZoneList';

const TimesZone = ({onHide,callback}) => {
    const [selectedItem,setSelectedItem] = useState();
    const {currentTenantId,currentCompanyId,currentBranchId,currentLangId,userId} = useCurrent();
    const {data:resultPost,loading:loadingPost,postData} = usePost();
    const {data:resultPut,loading:loadingPut,putData} = usePut();
    const {data:dataList,loading:loadingList,refreshHandler:getList} = useFetch(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/define-settings/${currentTenantId}/${currentCompanyId}/times-zone`);
    const {data:deleteResult,loading:deletingLoading,deleteItem} = useDelete();
    const [saved,setSaved] = useState(false);
    const [errors,setErrors] = useState({});
    const [mode,setMode] = useState('Adding');
    const [formState,setFormState] = useState({
        label: '',
    });

    const restFrom = () => {
        setFormState({
            label: '',
        });
    }
    const deleteHandler = async(id) =>{
        await deleteItem(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/define-settings/${id}`);
        console.log('Deleting',id);
        getList();
    }
    const saveHandler = async() => {
        setErrors({});
        let _errors = {};
        if(!formState.label){
            _errors = {..._errors,label: 'من فضلك ادخل اسم المنطقة'};
        }


        if(Object.entries(_errors).length > 0){
            setErrors(_errors);
            return;
        }
        const _data ={
            setting_name : "times-zone",
            setting_class : "labelAsValue",
            label: formState.label,
            value : formState.label,
            lang : currentLangId,
            tenant_id : currentTenantId,
            company_id : currentCompanyId,
            created_by : userId
        }
        if(mode === 'Adding'){
            await postData(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/define-settings`,_data);
        }else if(mode === 'Editing'){
            await putData(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/define-settings/${selectedItem.id}`,_data);

        }
        setMode('Adding');
        
    };

    useEffect(() => {
        if(mode === 'Editing'){
            console.log("Editing" , selectedItem );
            setFormState({
                label: selectedItem.label,
            });
        }
    },[mode]);
    
    useEffect(() =>{
        if(resultPost || resultPut){
            restFrom()
            setSaved(true);
            setTimeout(() => {
                setSaved(false);
            }, 1500);
            getList()
           onHide && callback();
            console.log(resultPost);
        }
    },[resultPost,resultPut]);



    return (
        <div className='flex flex-col gap-5 p-3'>
            <CardHeaderWithActions title={`إدارة مناطق التوقيت `}>
                <MwButton type='cancelBtn' inGroup={true} onClick={()=>onHide()}>إغلاق</MwButton>
                <MwButton type='saveBtn' inGroup={true} onClick={saveHandler}>حفظ</MwButton>
            </CardHeaderWithActions>

            {!!saved && <AlertMsg>تم الحفظ بنجاح</AlertMsg> }

            <AddNewTimeZone
                errors = {errors}
                formState = {formState}
                setFormState = {setFormState}
                
                />
            
            {
                !loadingList ? <TimeZoneList
                    dataList = {dataList?.data}
                    deleteHandler = {deleteHandler}
                    deletingLoading = {deletingLoading}
                    setMode = {setMode}
                    setSelectedItem = {setSelectedItem}
                    /> : <MwSpinnerButton withLabel={false} isFullCenter={true} />
            }
        </div>
    )
}

export default TimesZone