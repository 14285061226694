import React from 'react'
import CardItem from '../ui/CardItem'
import { FiEdit } from "react-icons/fi";
import { Link, useParams } from 'react-router-dom';



const ListEmployeesItem = ({item,index,currentLangId}) => {
    const params = useParams();

    return (
        <CardItem>
            <div className='w-12 text-center'>{index}</div>
            <div className='w-16 text-center'>{item.emp_no}</div>
            { currentLangId ==='en' ? <div className='flex-1 '>{item.name_en}</div> : 
            <div className='flex-1'>{item.name_ar}</div> }
            <div className='w-32 '>{item?.department_relation?.name}</div>
            <div className='w-4'><Link to={`/${params.tenant}/employees/${item.id}`}><FiEdit /></Link></div>
        </CardItem>
    )
}

export default ListEmployeesItem