import React from 'react'
import { MwInputText, MwSelector, TitleGroup } from '../ui'

const FormPrivateInfoContact = ({
    privateInfo,
    setPrivateInfo,
    bankAccountList,
    bankAccountSelected,
    setBankAccountSelected,
    languagesList,
    languagesListSelected,
    setLanguagesListSelected
    
}) => {
    return (
        <div>
            <TitleGroup>معلومات التواصل الخاصة</TitleGroup>
            <div className='flex-1'>
                <MwInputText
                    label="عنوان المنزل"
                    value = {privateInfo?.homeAddress}
                    onChange = { (e) => setPrivateInfo({...privateInfo,homeAddress:e.target.value})}
                    classNameInput = {`font-bold `}
                />
            </div>
            <div className='flex gap-5'>
                <div className='flex-1'>
                    <MwInputText
                        label="البريد الالكتروني الشخصي"
                        value = {privateInfo?.email}
                        onChange = { (e) => setPrivateInfo({...privateInfo,email:e.target.value})}
                        classNameInput = {`font-bold `}
                    />
                </div>
                <div className='flex-1'>
                    <MwInputText
                        label="الهاتف الشخصي"
                        value = {privateInfo?.phone}
                        onChange = { (e) => setPrivateInfo({...privateInfo,phone:e.target.value})}
                        classNameInput = {`font-bold `}
                    />
                </div>
            </div>
            <div className='relative flex-1'>
                <MwSelector
                    label={`رقم الحساب البنكي`}
                    initalValue='أختار الحساب...'
                    dataType='labelValue'
                    _data={bankAccountList}
                    selectedItem={bankAccountSelected}
                    setSelectedItem={setBankAccountSelected}
                    withAddNew = {false}
                />
            </div>
            <div className='flex gap-5'>
                <div className='flex-1'>
                    <MwInputText
                        label="المسافة بين العمل والمنزل"
                        value = {privateInfo?.homeWorkDistance}
                        onChange = { (e) => setPrivateInfo({...privateInfo,homeWorkDistance:e.target.value})}
                        classNameInput = {`font-bold `}
                    />
                </div>
                <div className='relative flex-1'>
                    <MwSelector
                        label={`اللغة الام`}
                        initalValue='أختار اللغة...'
                        dataType='labelValue'
                        _data={languagesList}
                        selectedItem={languagesListSelected}
                        setSelectedItem={setLanguagesListSelected}
                        withAddNew = {false}
                    />
                </div>
            </div>
        </div>
    )
}

export default FormPrivateInfoContact