import React from 'react'
import { MwInputText, MwSelector, MwTextArea } from '../ui'

const AddNewResumeLine = ({formState,setFormState,errors,typeSelected,setTypeSelected}) => {
    const typeList = [
        {
            label:'Education',
            value:'education',
            lang:'en'
        },
        {
            label:'Experience',
            value:'experience',
            lang:'en'
        },
        {
            label:'تعليم',
            value:'education',
            lang:'ar'
        },
        {
            label:'خبرات',
            value:'experience',
            lang:'ar'
        }
    ]
    return (
        <>
            <div className='bg-slate-50 rounded-lg text-sm p-2'>
                <div className='text-sm font-bold text-slate-500 p-2'>
                    <span> أضف تدريب / خبرات جديدة </span>
                </div>
                <div className='flex-1'>
                    <MwInputText
                        label="اسم التدريب/الخبرة "
                        value = {formState.title}
                        onChange = { (e) => setFormState({ ...formState,title:e.target.value}) }
                        classNameInput = {`font-bold `}
                        invalid={!!errors?.title}
                        invalidMsg={errors?.title}
                        />
                </div>
                <div>
                    <MwSelector
                        label={`النوع`}
                        initalValue='اختار النوع...'
                        _data={typeList}
                        selectedItem={typeSelected}
                        setSelectedItem={setTypeSelected}
                        withAddNew = {false}
                        dataType='labelValue'
                        invalid={!!errors?.typeSelected}
                        invalidMsg={errors?.typeSelected}
                        />
                </div>
                <div className='flex gap-3'>
                    <div className='flex-1'>
                        <MwInputText
                            label="تاريخ البداية"
                            inputType='date'
                            value = {formState.dateStart}
                            onChange = { (e) => setFormState({ ...formState,dateStart:e.target.value}) }
                            classNameInput = {`font-bold `}
                            invalid={!!errors?.dateStart}
                            invalidMsg={errors?.dateStart}
                            />
                    </div>
                    <div className='flex-1'>
                        <MwInputText
                            label="تاريخ النهاية"
                            inputType='date'
                            value = {formState.dateEnd}
                            onChange = { (e) => setFormState({ ...formState,dateEnd:e.target.value}) }
                            classNameInput = {`font-bold `}
                            invalid={!!errors?.dateEnd}
                            invalidMsg={errors?.dateEnd}
                            />
                    </div>
                </div>
                <div>
                    <MwTextArea
                        label="الوصف"
                        value = {formState.description}
                        onChange = { (e) => setFormState({ ...formState,description:e.target.value}) }
                        classNameInput = {`font-bold `}
                        invalid={!!errors?.description}
                        invalidMsg={errors?.description}
                        />
                </div>
            </div>
        </>
    )
}

export default AddNewResumeLine