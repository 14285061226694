import React, { useEffect, useState } from 'react'
import { useCurrent,useDelete,useFetch,usePost, usePut } from '../hooks';
import AddNewWorkingHours from '../components/working-hours/AddNewWorkingHours';
import CardHeaderWithActions from '../components/ui/CardHeaderWithActions';
import { MwButton, MwSpinnerButton } from '../components/ui';
import WorkingHoursList from '../components/working-hours/WorkingHoursList';
import AlertMsg from '../components/ui/AlertMsg';

const WorkingHours = ({onHide,callback}) => {
    const [selectedItem,setSelectedItem] = useState();
    const {currentTenantId,currentCompanyId,currentBranchId,currentLangId,userId} = useCurrent();
    const {data:resultPost,loading:loadingPost,postData} = usePost();
    const {data:resultPut,loading:loadingPut,putData} = usePut();
    const {data:workingHoursList,loading:loadingList,refreshHandler:getList} = useFetch(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/define-settings/${currentTenantId}/${currentCompanyId}/working-hours`);
    const {data:deleteResult,loading:deletingLoading,deleteItem} = useDelete();
    const [saved,setSaved] = useState(false);
    const [errors,setErrors] = useState({});
    const [mode,setMode] = useState('Adding');
    const [workingHourState,setWorkingHourState] = useState({
        label: '',
        startTime:'',
        endTime:'',
    });

    const restFrom = () => {
        setWorkingHourState({
            label: '',
            startTime:'',
            endTime:'',
        });
    }
    const deleteHandler = async(id) =>{
        await deleteItem(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/define-settings/${id}`);
        console.log('Deleting',id);
        getList();
    }
    const saveHandler = async() => {
        setErrors({});
        let _errors = {};
        if(!workingHourState.label){
            _errors = {..._errors,label: 'من فضلك ادخل اسم الدوام'};
        }
        if(!workingHourState.startTime ){
            _errors = {..._errors,startTime: 'من فضلك ادخل بداية الدوام'};
        }
        if( !workingHourState.endTime){
            _errors = {..._errors,endTime: 'من فضلك ادخل نهاية الدوام'};
        }

        if(Object.entries(_errors).length > 0){
            setErrors(_errors);
            return;
        }
        const _data ={
            setting_name : "working-hours",
            setting_class : "arrayOfString",
            label: workingHourState.label,
            value : JSON.stringify([workingHourState.startTime, workingHourState.endTime]),
            lang : currentLangId,
            tenant_id : currentTenantId,
            company_id : currentCompanyId,
            created_by : userId
        }
        if(mode === 'Adding'){
            await postData(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/define-settings`,_data);
        }else if(mode === 'Editing'){
            await putData(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/define-settings/${selectedItem.id}`,_data);

        }
        setMode('Adding');
        
    };

    useEffect(() => {
        if(mode === 'Editing'){
            console.log("Editing" , selectedItem );
            const _time = JSON.parse(selectedItem.value)
            setWorkingHourState({
                label: selectedItem.label,
                startTime:_time[0],
                endTime: _time[1],
            });
        }
    },[mode]);
    
    useEffect(() =>{
        if(resultPost || resultPut){
            restFrom()
            setSaved(true);
            setTimeout(() => {
                setSaved(false);
            }, 1500);
            getList()
            onHide && callback();
            console.log(resultPost);
        }
    },[resultPost,resultPut]);

    useEffect(() => {
        if(workingHoursList){
            console.log('workingHoursList',workingHoursList)
        }
    },[workingHoursList]);

    return (
        <div className='flex flex-col gap-5 p-3'>
            <CardHeaderWithActions title={`إدارة الدوام / اوقات العمل`}>
                <MwButton type='cancelBtn' inGroup={true} onClick={()=>onHide()}>إغلاق</MwButton>
                <MwButton type='saveBtn' inGroup={true} onClick={saveHandler}>حفظ</MwButton>
            </CardHeaderWithActions>

            {!!saved && <AlertMsg>تم الحفظ بنجاح</AlertMsg> }

            <AddNewWorkingHours
                errors = {errors}
                workingHourState = {workingHourState}
                setWorkingHourState = {setWorkingHourState}
                
                />
            
            {
                !loadingList ? <WorkingHoursList
                    workingHoursList = {workingHoursList?.data}
                    deleteHandler = {deleteHandler}
                    deletingLoading = {deletingLoading}
                    setMode = {setMode}
                    setSelectedItem = {setSelectedItem}
                    /> : <MwSpinnerButton withLabel={false} isFullCenter={true} />
            }
        </div>
    )
}

export default WorkingHours