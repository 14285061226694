import React from 'react'
import ListDepartmentsItem from './ListDepartmentsItem'
import CardItem from '../ui/CardItem'

const ListDepartments = ({data,employeesCount,onDepartmentChange,selected}) => {
    return (
        <>
        {
            data?.length > 0 &&
                <div className='text-sm text-slate-500'>
                    <CardItem selected={selected===0}  onClick={()=>onDepartmentChange(0)}>
                            <span> عرض الكل  </span>
                            <span>[<span className='font-bold'>{employeesCount}</span>]</span>
                    </CardItem>
                    <div className='max-h-[calc(100vh-270px)] overflow-auto'>
                        { data?.map((item,index) =>(
                            <ListDepartmentsItem item={item} key={index} selected={selected} onDepartmentChange={onDepartmentChange}/>
                        )) 
                        }
                    </div>
                    <div className='  px-2 pt-2'> 
                        <span >عدد الاقسام : </span>
                        <span className='font-bold'>{data?.length}</span>
                    </div>
                </div>
        }
        </>
    )
}

export default ListDepartments