import React from 'react'
import {DataListView, DataListViewBody, DataListViewHead, DataListViewRow} from '../../components/ui/data-view/'
import {MwSpinnerButton, NoDataFound} from '../../components/ui/'
import AttendanceLogListItem from './AttendanceLogListItem'

const AttendanceLogList = ({data,loading,currentLangId}) => {
    return (
        <div className='flex-1'>
            {loading ? 
            <MwSpinnerButton withLabel={false} isFullCenter={true}/> :
            data?.data?.length > 0 ?
            <DataListView>
                <DataListViewHead>
                    <div className='w-20 text-center'>رقم الموظف</div>
                    <div className='flex-1'>اسم الموظف</div>
                    <div className='w-20 text-center'> نوع الإجراء</div>
                    <div className='w-20 text-center'> الجهاز المستخدم </div>
                    <div className='w-32 text-center'> التاريخ</div>
                </DataListViewHead>
                <DataListViewBody>
                    {
                        data?.data.map(item => ( <AttendanceLogListItem key={item.id} item={item} currentLangId={currentLangId} /> ))
                    }
                </DataListViewBody>
            </DataListView> : 
            <NoDataFound msg={'لا يوجد سجلات'} />
            }
        </div>
    )
}

export default AttendanceLogList