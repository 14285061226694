import React,{useState} from 'react'
import { MwInputText, MwSelector, MwSpinnerButton, SideModal, TagList } from '../ui'
import FormWorkInfo from './FormWorkInfo';
import FormResume from './FormResume';
import FormPrivateInfo from './FormPrivateInfo';
import ManageDepartment from '../../pages/ManageDepartment';
import JobPosition from '../../pages/JobPosition';
import Locations from '../../pages/Locations';
import WorkingHours from '../../pages/WorkingHours';
import TimesZone from '../../pages/TimesZone';
import ResumeLines from '../../pages/employee/ResumeLines';
import Skills from '../../pages/employee/Skills';
import MwInputFile from '../ui/MwInputFile';
import FormConfig from './FormConfig';

const FormEmployee = ({
    errors,
    jobPositionListData,
    jobPositionSelected,
    getAllJobPosition,
    setJobPositionSelected,
    employee,
    setEmployee,
    selectedImage,
    loadingUploadPic,
    handleImageUpload,
    empTagList,
    setEmpTagList,
    departmentList,
    departmentSelected,
    setDepartmentSelected,
    employeesList,
    managerSelected,
    setManagerSelected,
    coachList,
    coachSelected,
    setCoachSelected,
    getDepartments,
    privateInfo,
    setPrivateInfo,
    nationalityList,
    nationalitySelected,
    setNationalitySelected,
    genderList,
    genderSelected,
    setGenderSelected,
    maritalStatusList,
    maritalStatusSelected,
    setMaritalStatusSelected,
    certificateLevelList,
    certificateLevelSelected,
    setCertiificationLevelSelected,
    bankAccountList,
    bankAccountSelected,
    setBankAccountSelected,
    languagesList,
    languagesListSelected,
    setLanguagesListSelected,
    locationList,
    locationSelected,
    setLocationSelected,
    getAllLocations,
    showLocationModal,
    setShowLocationModal,
    approversExpenseSelected,
    setApprovalsExpenseSelected,
    approversTimeoffSelected,
    setApprovalsTimeoffSelected,
    workingHoursList,
    getWorkingHoursList,
    workingHoursSelected,
    setWorkingHoursSelected,
    timezoneList,
    timezoneSelected,
    setTimezoneSelected,
    getTimeszoneList,
    showWorkingHoursModal,
    setShowWorkingHoursModal,
    showTimezoneModal,
    setShowTimezoneModal,
    showResumeLines,
    setShowResumeLines,
    getResumeLines,
    resumeLinesList,
    setResumeLinesList,
    getSkills,
    showSkills,
    setShowSkills,
    skillsList,
    setSkillsList,
    handlePermitFileUpload,
    selectedPermit,
    loadingPermitFile,
    typeEmployeeSelected,
    setTypeEmployeeSelected,
    relatedUserSelected,
    setRelatedUserSelected,
    employeeConfigState,
    setEmployeeConfigState,
    contractSelected,
    setContractSelected,
}) => {
    const [tabActive,setTabActive] = useState('RESUME_TAB');
    const [showNewDepartment,setShowNewDepartment] = useState(false);
    const [showJobPosition,setShowJobPosition] = useState(false);
    
    return (
        <>
            <SideModal onShow={showNewDepartment}  setOnShow={setShowNewDepartment} title={`أضف قسم جديد`}>
                {showNewDepartment ? <ManageDepartment callback={getDepartments}  /> : <div></div> }
            </SideModal>
            <SideModal onShow={showJobPosition}  setOnShow={setShowJobPosition} title={`المسميات الوظيفية`}>
                {showJobPosition ? <JobPosition callbackSave={getAllJobPosition} /> : <div></div> }
            </SideModal>
            <SideModal onShow={showLocationModal} setOnShow={setShowLocationModal}>
                {showLocationModal ? <Locations callback={getAllLocations}  /> : <></>}
            </SideModal>
            <SideModal onShow={showWorkingHoursModal} setOnShow={setShowWorkingHoursModal}>
                {showWorkingHoursModal ? <WorkingHours  callback={getWorkingHoursList}  /> : <></>}
            </SideModal>
            <SideModal onShow={showTimezoneModal} setOnShow={setShowTimezoneModal}>
                {showTimezoneModal ? <TimesZone  callback={getTimeszoneList}  /> : <></>}
            </SideModal>
            <SideModal onShow={showResumeLines} setOnShow={setShowResumeLines}>
                {showResumeLines ? <ResumeLines  callback={getResumeLines} resumeLinesList = {resumeLinesList}
                setResumeLinesList = {setResumeLinesList}  /> : <></>}
            </SideModal>
            <SideModal onShow={showSkills} setOnShow={setShowSkills}>
                {showSkills ? <Skills  callback={getSkills} skillsList = {skillsList}
                setSkillsList = {setSkillsList}  /> : <></>}
            </SideModal>
            <div>
                <div className='w-full p-2'>
                    <div className='flex'>
                        <div className='flex-1 flex flex-col gap-3'>
                            <div className='flex gap-3'>
                                <div>
                                    <MwInputText
                                        label="رقم الموظف"
                                        value = {employee.empNo}
                                        onChange = { (e) => setEmployee({...employee,empNo:e.target.value})}
                                        classNameInput = {`font-bold`}
                                    />
                                </div>
                                <div className='relative flex-1'>
                                    <TagList
                                        withLabel={true}
                                        label={`العلامات Tags`}
                                        tagList={empTagList}
                                        setTagList={setEmpTagList}
                                    />
                                </div>
                            </div>
                            <hr className='my-1'/>
                            <div className='flex justify-between gap-3 items-center'>
                                <div className='flex-1'>
                                    <MwInputText
                                        label="اسم الموظف (English)"
                                        value = {employee.nameEn}
                                        onChange = { (e) => setEmployee({...employee,nameEn:e.target.value})}
                                        classNameInput = {`font-bold `}
                                        invalid = {!!errors.nameEn}
                                        invalidMsg = {errors.nameEn}
                                        
                                    />
                                </div>
                                <div className='flex-1'>
                                    <MwInputText
                                        label="اسم الموظف (عربي)"
                                        value = {employee.nameAr}
                                        onChange = { (e) => setEmployee({...employee,nameAr:e.target.value})}
                                        classNameInput = {`font-bold `}
                                    />
                                </div>
                                <div  className='relative flex-1 z-20'>
                                    <MwSelector
                                            label={`القسم التابع له الموظف`}
                                            dataType='labelValue'
                                            initalValue='أختار القسم...'
                                            _data={departmentList}
                                            selectedItem={departmentSelected}
                                            setSelectedItem={setDepartmentSelected}
                                            onClickNew={()=>setShowNewDepartment(true)}
                                        />
                                </div>
                            </div>
                            
                            <div className='flex gap-3'>
                                <div className='relative flex-1'>
                                    <MwSelector
                                        label={`المسمي الوظيفي`}
                                        initalValue='أختار المسمي الوظيفي'
                                        dataType='labelValue'
                                        _data={jobPositionListData}
                                        selectedItem={jobPositionSelected}
                                        setSelectedItem={setJobPositionSelected}
                                        onClickNew={() => setShowJobPosition(true)}
                                    />
                                </div>
                                
                                <div className='relative flex-1'>
                                    <MwSelector
                                        label={` المدير المباشر للموظف`}
                                        initalValue='أختار المدير المباشر...'
                                        dataType='labelValue'
                                        _data={employeesList}
                                        selectedItem={managerSelected}
                                        setSelectedItem={setManagerSelected}
                                        withAddNew = {false}
                                    />
                                </div>
                                <div className='relative flex-1'>
                                    <MwSelector
                                            label={`مسئول التدريب للموظف`}
                                            initalValue='أختار مسئول التدريب...'
                                            dataType='labelValue'
                                            _data={coachList}
                                            selectedItem={coachSelected}
                                            setSelectedItem={setCoachSelected}
                                            withAddNew = {false}
                                        />
                                </div>
                            </div>
                            <div className='flex justify-between gap-3 items-center'>
                                <div className='flex-1'>
                                    <MwInputText
                                        label="رقم جوال الخاص بالعمل"
                                        value = {employee.workMobile}
                                        onChange = { (e) => setEmployee({...employee,workMobile:e.target.value})}
                                        classNameInput = {`font-bold `}
                                        
                                    />
                                </div>
                                <div className='flex-1'>
                                    <MwInputText
                                        label="رقم الهاتف الخاص بالعمل"
                                        value = {employee.workPhone}
                                        onChange = { (e) => setEmployee({...employee,workPhone:e.target.value})}
                                        classNameInput = {`font-bold `}
                                    />
                                </div>
                                <div className='flex-1'>
                                    <MwInputText
                                        label="البريد الالكتروني الخاص بالعمل"
                                        value = {employee.workEmail}
                                        onChange = { (e) => setEmployee({...employee,workEmail:e.target.value})}
                                        classNameInput = {`font-bold `}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='w-64 ps-3 flex flex-col items-center justify-center'>
                            { 
                                <img src={ !!selectedImage ? selectedImage :'/assets/images/new-employee.jpg'} alt="Employee Image" className='rounded-t-3xl'  />
                            }
                            <MwInputFile loading={loadingUploadPic} onChangeHandler={handleImageUpload} /> 
                        </div>
                    </div>
                    {/* <hr className='my-3' /> */}
                    <div className='pt-5' >
                        <ul className='flex  justify-evenly items-center text-sm'>
                            <li onClick={()=>setTabActive('RESUME_TAB')} className={`flex-1 cursor-default border py-3 px-3 text-center rounded-t-2xl ${tabActive === 'RESUME_TAB' && 'bg-slate-100'}`}>السيرة الذاتية </li>
                            <li onClick={()=>setTabActive('WORK_INFO_TAB')} className={`flex-1 cursor-default border py-3 px-3 text-center rounded-t-2xl ${tabActive === 'WORK_INFO_TAB' && 'bg-slate-100'}`}> معلومات العمل </li>
                            <li onClick={()=>setTabActive('PRIVATE_INFO_TAB')} className={`flex-1 cursor-default border py-3 px-3 text-center rounded-t-2xl ${tabActive === 'PRIVATE_INFO_TAB' && 'bg-slate-100'}`}> المعلومات الشخصية </li>
                            <li onClick={()=>setTabActive('CONFIG_TAB')} className={`flex-1 cursor-default border py-3 px-3 text-center rounded-t-2xl ${tabActive === 'CONFIG_TAB' && 'bg-slate-100'}`}> اعدادات الموظف </li>
                        </ul>
                        <div className='border-l border-r border-b rounded-b-2xl py-3 px-2 min-h-80 bg-slate-100 max-h-[calc(100vh-570px)] overflow-auto '>
                            { tabActive === 'RESUME_TAB' && <FormResume
                                                                setShowResumeLines = {setShowResumeLines}
                                                                resumeLinesList = {resumeLinesList}
                                                                setShowSkills ={setShowSkills}
                                                                skillsList = {skillsList}
                                                                setSkillsList = {setSkillsList}
                            /> }
                            { tabActive === 'WORK_INFO_TAB' && <FormWorkInfo
                                                locationList = {locationList}
                                                locationSelected = {locationSelected}
                                                setLocationSelected = {setLocationSelected}
                                                setShowLocationModal = {setShowLocationModal}
                                                employeesList = {employeesList}
                                                approversExpenseSelected = {approversExpenseSelected}
                                                setApprovalsExpenseSelected = {setApprovalsExpenseSelected}
                                                approversTimeoffSelected = {approversTimeoffSelected}
                                                setApprovalsTimeoffSelected = {setApprovalsTimeoffSelected}
                                                workingHoursList = {workingHoursList}
                                                workingHoursSelected = {workingHoursSelected}
                                                setWorkingHoursSelected = {setWorkingHoursSelected}
                                                timezoneList = {timezoneList}
                                                timezoneSelected = {timezoneSelected}
                                                setTimezoneSelected = {setTimezoneSelected}
                                                showWorkingHoursModal = {showWorkingHoursModal}
                                                setShowWorkingHoursModal = {setShowWorkingHoursModal}
                                                showTimezoneModal = {showTimezoneModal}
                                                setShowTimezoneModal = {setShowTimezoneModal}
                                /> }
                            { tabActive === 'PRIVATE_INFO_TAB' && 
                                <FormPrivateInfo
                                    privateInfo = {privateInfo}
                                    setPrivateInfo = {setPrivateInfo}
                                    nationalityList = {nationalityList}
                                    nationalitySelected = {nationalitySelected}
                                    setNationalitySelected = {setNationalitySelected}
                                    genderList = {genderList}
                                    genderSelected  = {genderSelected}
                                    setGenderSelected   = {setGenderSelected}
                                    maritalStatusList   = {maritalStatusList}
                                    maritalStatusSelected   = {maritalStatusSelected}
                                    setMaritalStatusSelected = {setMaritalStatusSelected}
                                    certificateLevelList = {certificateLevelList}
                                    certificateLevelSelected = {certificateLevelSelected} 
                                    setCertiificationLevelSelected = {setCertiificationLevelSelected}
                                    bankAccountList = {bankAccountList}
                                    bankAccountSelected = {bankAccountSelected} 
                                    setBankAccountSelected = {setBankAccountSelected} 
                                    languagesList = {languagesList}
                                    languagesListSelected = {languagesListSelected}
                                    setLanguagesListSelected = {setLanguagesListSelected}
                                    handlePermitFileUpload = {handlePermitFileUpload}
                                    loadingPermitFile = {loadingPermitFile}
                                    selectedPermit = {selectedPermit}
                                />
                            }
                            {
                                tabActive === 'CONFIG_TAB' && 
                                    <FormConfig
                                        typeEmployeeSelected = {typeEmployeeSelected}
                                        setTypeEmployeeSelected = {setTypeEmployeeSelected}
                                        relatedUserSelected = {relatedUserSelected}
                                        setRelatedUserSelected = {setRelatedUserSelected}
                                        employeeConfigState = {employeeConfigState}
                                        setEmployeeConfigState = {setEmployeeConfigState}
                                        contractSelected = {contractSelected}
                                        setContractSelected = {setContractSelected}
                                        />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FormEmployee