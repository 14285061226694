import React from 'react'
import { AiOutlineDelete } from "react-icons/ai";
import { FaRegEdit } from "react-icons/fa";
import { BiShow } from "react-icons/bi";

const ListLocationItem = ({item,index,deleteHandler,setLocationSelected,setMode}) => {
    
    const _deleteHandler = (item) => {
        setLocationSelected(item)
        deleteHandler()
    }
    const editHandler = (item) =>{
        console.log(item)
        setLocationSelected(item)
        setMode('Editing');
    };

    return (
        <div className='flex gap-5 p-1 m-1 border-t border-slate-300 ' >
            <div className='w-4'>{index+1}</div>
            <div className='flex-1'>{item.name}</div>
            <div className='flex-1'>{item.address_relation?.country_relation?.country_name_ar}</div>
            <div className='flex-1'>{item.address_relation?.city}</div>
            <div className='flex-1'>{item.address_relation?.region}</div>
            <div className='w-28 flex gap-3 justify-center items-center'>
                <AiOutlineDelete onClick={()=>_deleteHandler(item)} size={16} />
                <FaRegEdit onClick={()=>editHandler(item)} size={16}/>
                {/* <BiShow size={16}/> */}
            </div>
        </div>
    )
}

export default ListLocationItem