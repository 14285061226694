import React from 'react'
import ListLocationItem from './ListLocationItem'
import { CardBasic, MwSpinnerButton, NoDataFound } from '../ui'

const ListLocation = ({data,setLocationSelected,setMode,deleteHandler,loading}) => {

    return (
        <>
            {loading ? <MwSpinnerButton withLabel={false} isFullCenter={true} /> :
            data?.length > 0 ?
                <div className='p-3'>
                    <div className='text-sm font-bold text-slate-500 p-2'>
                        <span>  قائمة المواقع  </span>
                    </div>
                    <CardBasic>
                        <div className='w-full flex gap-5 font-bold p-2'>
                            <div className='w-4'> # </div>
                            <div className='flex-1'> اسم الموقع </div>
                            <div className='flex-1'>الدولة</div>
                            <div className='flex-1'>المدينة</div>
                            <div className='flex-1'>الحي</div>
                            <div className='w-28 text-center'>الإجراءات</div>
                        </div>
                        {
                            data?.map((item,index) =>(<ListLocationItem key={item.id} index={index} deleteHandler={deleteHandler} setLocationSelected={setLocationSelected} setMode={setMode} item={item}/>))
                        }
        
                    </CardBasic>
                </div> :
                <NoDataFound msg={` لا يوجد مواقع `} />
            }
        </>

    )
}

export default ListLocation