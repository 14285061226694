import React from 'react'
import { RiDeleteBin5Line } from "react-icons/ri";

const SkillsTypesListItem = ({item,index,deleteHandler}) => {
    return (
        <div className='bg-slate-200 rounded-lg p-3 my-5'>
            <div className='flex justify-between items-center font-bold text-sm '>
                <span>{item.name}</span>
                <RiDeleteBin5Line size={16} onClick={()=>deleteHandler(item.id)}/>
            </div>
            <hr />
            <div className='px-5 flex gap-3'>
                {
                    item.skills.map((skill,i) => (<span key={i}>{skill?.title}</span>))
                }
            </div>
            <hr />
            <div className='px-5 flex flex-col gap-1'>
                {
                    item.levels.map((level,i) => (
                        <div className='flex gap-3 items-center py-1' key={i}>
                            <div>{level?.title}</div>
                            <div className='flex-1 bg-slate-300 rounded-lg'>
                            { level.level_percent && <div className={`bg-slate-400 ${level.level_percent > 98 ? 'rounded-lg' : 'rounded-r-lg'}  text-center text-slate-50`} style={{ width: level.level_percent+"%" }}>{level.level_percent}%</div> }
                        </div>
                        </div>
                        ))
                }
            </div>
        </div>
    )
}

export default SkillsTypesListItem