import React, { useState } from 'react'
import CardItem from '../ui/CardItem'

const ListDepartmentsItem = ({item,onDepartmentChange,selected}) => {
    
    // const [selectedItem,setSelectedItem] = useState(false);

    // if(item.id===selected) setSelectedItem(true);

    return (
        <CardItem selected={item.id===selected}  onClick={()=>onDepartmentChange(item.id)}>
            <div>{item.name}</div>
            <div>[<span className='font-bold'>{item.employees_count}</span>]</div>
        </CardItem>
        )
}

export default ListDepartmentsItem