import React, { useEffect, useState } from 'react'
import { MwInputText, MwSelector, TitleGroup } from '../ui'
import { useCurrent, useFetch } from '../../hooks'

const FormConfig = ({
    typeEmployeeSelected,
    setTypeEmployeeSelected,
    relatedUserSelected,
    setRelatedUserSelected,
    employeeConfigState,
    setEmployeeConfigState,
    contractSelected,
    setContractSelected,
}) => {
    const [_usersList,_setUsersList] = useState([]);
    const {currentTenantId,currentCompanyId,currentLangId} = useCurrent();
    const {data:resultusers,loading:loadingUsers} = useFetch(`${process.env.REACT_APP_Auth_API_BASE_URL}/users/tenant/${currentTenantId}/${currentCompanyId}`);

    

    const _employeeTypeList =[
        {label : "Employee" , value :"Employee",lang:"en"},
        {label : "موظف" , value :"Employee",lang:"ar"},
        {label : "Student" , value :"Student",lang:"en"},
        {label : "طالب" , value :"Student",lang:"ar"},
        {label : "Training" , value :"Training",lang:"en"},
        {label : "تدريب" , value :"Training",lang:"ar"},
        {label : "Constractor" , value :"Constractor",lang:"en"},
        {label : "مدرب" , value :"Constractor",lang:"ar"},
        {label : "Freelancer" , value :"Freelancer",lang:"en"},
        {label : "متعاون" , value :"Freelancer",lang:"ar"},
    ];

    useEffect(() =>{
        if(resultusers ){
            if(resultusers?.data.length > 0){
                const _list = resultusers.data.map(user =>({
                    label : user.name,
                    value: user._id,
                    lang: currentLangId
                }));
                _setUsersList(_list);
            }
        }
    },[resultusers]);

    return (
        <div className='px-3'>
            <div className='flex gap-5'>
                <div className='flex-1 flex flex-col gap-2'>
                    <TitleGroup>حالة الموظف</TitleGroup>
                    <MwSelector
                    className={`z-20`}
                        label={`نوع الموظف`}
                        initalValue='اختار نوع الموظف...'
                        _data={_employeeTypeList}
                        selectedItem={typeEmployeeSelected}
                        setSelectedItem={setTypeEmployeeSelected}
                        withAddNew={false}
                        dataType={`labelValue`}
                        />
                    <MwSelector
                        className={`z-30`}
                        label={`المستخدم المرتبط بالموظف`}
                        initalValue='اختار المستخدم...'
                        _data={_usersList}
                        selectedItem={relatedUserSelected}
                        setSelectedItem={setRelatedUserSelected}
                        withAddNew={false}
                        dataType={`labelValue`}
                        />
                </div>
                <div className='flex-1 flex flex-col gap-2'>
                    <TitleGroup>الحضور والانصراف</TitleGroup>
                    <MwInputText
                        label="PIN Code"
                        value = {employeeConfigState?.pincode}
                        onChange = { (e) => setEmployeeConfigState({...employeeConfigState,pincode:e.target.value})}
                        classNameInput = {`font-bold `}
                    />
                    <MwInputText
                        label="Badge ID"
                        value = {employeeConfigState?.badgeId}
                        onChange = { (e) => setEmployeeConfigState({...employeeConfigState,badgeId:e.target.value})}
                        classNameInput = {`font-bold `}
                    />
                </div>
            </div>
            <div className='flex gap-5 pt-3'>
                <div className='flex-1 flex flex-col gap-2'>
                    <TitleGroup>الرواتب</TitleGroup>
                    <MwSelector
                        className={`z-0`}
                        label={`العقد الحالي`}
                        initalValue='اختار عقد الموظف...'
                        _data={[]}
                        selectedItem={contractSelected}
                        setSelectedItem={setContractSelected}
                        withAddNew={false}
                        dataType={`labelValue`}
                        />
                </div>
                <div className='flex-1 flex flex-col gap-2'>
                    <TitleGroup>الصلاحيات</TitleGroup>
                    <div className='grid grid-cols-2'>
                        <div className='flex gap-1 items-center text-sm rounded-xl bg-slate-200 py-1 px-3 m-2 text-slate-600 '>
                            <input 
                                type="checkbox" 
                                checked = {employeeConfigState?.isManager}
                                onChange = { (e) => setEmployeeConfigState({...employeeConfigState,isManager: !employeeConfigState.isManager})}
                                id='isManager'  />
                            <label htmlFor='isManager'>مدير مباشر</label>
                        </div>
                        <div className='flex gap-1 items-center text-sm rounded-xl bg-slate-200 py-1 px-3 m-2 text-slate-600 '>
                            <input 
                                type="checkbox"
                                checked = {employeeConfigState?.isTrainer}
                                onChange = { (e) => setEmployeeConfigState({...employeeConfigState,isTrainer: !employeeConfigState.isTrainer})}
                                id='isTrainer' />
                            <label htmlFor='traniner'> مسئول تدريب</label>
                        </div>
                        <div className='flex gap-1 items-center text-sm rounded-xl bg-slate-200 py-1 px-3 m-2 text-slate-600 '>
                            <input 
                                type="checkbox"
                                checked = {employeeConfigState?.isApprovalExpense}
                                onChange = { (e) => setEmployeeConfigState({...employeeConfigState,isApprovalExpense: !employeeConfigState.isApprovalExpense})}
                                id='isApprovalExpense' />
                            <label htmlFor='traniner'>اعتماد مصروفات</label>
                        </div>
                        <div className='flex gap-1 items-center text-sm rounded-xl bg-slate-200 py-1 px-3 m-2 text-slate-600 '>
                            <input 
                                type="checkbox" 
                                checked = {employeeConfigState?.isApprovalTimeoff}
                                onChange = { (e) => setEmployeeConfigState({...employeeConfigState,isApprovalTimeoff: !employeeConfigState.isApprovalTimeoff })}
                                id='traniner' />
                            <label htmlFor='traniner'>اعتماد اجازات</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormConfig