import React from 'react'

const CardItem = ({children,onClick,selected=false}) => {
    return (
        <div 
            onClick={onClick} 
            className={`flex justify-between gap-5 px-3 py-2 m-1 cursor-default text-slate-800 border-b border-slate-200 hover:bg-slate-200 hover:rounded-xl hover:font-bold ${selected && 'bg-slate-200 rounded-xl font-bold'}`}
            >
            {children}
        </div>
    )
}

export default CardItem