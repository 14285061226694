import React from 'react'

const CardBasic = ({children,className}) => {
    return (
        <div className={`text-xs rounded-xl bg-slate-100 p-3  ${className}`}>
            {children}
        </div>
    )
}

export default CardBasic