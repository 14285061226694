import {useEffect,useState} from 'react'
import { CiFilter } from "react-icons/ci";
import {  MwInputText,MwSelector, MwButton } from '../ui';
import {attendanceTypes} from '../../constants/attendance.js'
import {useCurrent,usePost} from '../../hooks/';

const AttendanceFilter = ({filterState,setFilterState,filterHandler,typeSelected,setTypeSelected,empSelected,setEmpSelected}) => {
  const [employeesList,setEmployeesList] = useState([]);
  const {currentTenantId,currentCompanyId,currentBranchId,currentLangId} = useCurrent();
  const {data:resultEmployeesData,loading:loadingEmployees,postData:postDataEmployees} = usePost();
  let _data = {
    tenant_id: currentTenantId,
    company_id: currentCompanyId,
    branch_id: currentBranchId,
}
  useEffect(() =>{
    postDataEmployees(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/employees`,_data);
  },[]);
  useEffect(() =>{
    if(resultEmployeesData){
        const empList = resultEmployeesData.data.map(employee => ({
            label:employee[`name_${currentLangId}`],
            value:employee.id,
            lang: currentLangId
            
        }));
        setEmployeesList(empList);
    }
},[resultEmployeesData]);
  return (
    <div className='w-56 rounded-2xl bg-slate-100  p-3 flex  flex-col gap-3  items-start '>
        <div className='flex justify-center items-center gap-1 text-slate-500 text-sm'>
            <CiFilter size={18}/>
            <span className=''>التصفية</span>
        </div>
        <div className='w-full z-50'>
            <MwSelector
                label={`الموظف`}
                initalValue='أختار الموظف...'
                dataType='labelValue'
                _data={[    {
                  label:'ALL',
                  value: null,
                  lang:'en'
              },
              {
                  label:'عرض الجميع',
                  value: null,
                  lang:'ar'
              },...employeesList]}
                selectedItem={empSelected}
                setSelectedItem={setEmpSelected}
                withAddNew = {false}
            />
        </div>
        <div className='w-full'>
            <MwInputText
                inputType='date'
                label='من تاريخ'
                value={filterState.startDate}
                onChange={(e)=>setFilterState({...filterState,startDate:e.target.value})}
                />
        </div>
        <div className='w-full'>
            <MwInputText
                inputType='date'
                label='الي تاريخ'
                value={filterState.endDate}
                onChange={(e)=>setFilterState({...filterState,endDate:e.target.value})}
                />
        </div>
        <div className='w-full relative z-30'>
            <MwSelector
                label={`نوع الإجراء`}
                initalValue={`عرض الجميع`}
                _data={attendanceTypes}
                selectedItem={typeSelected}
                setSelectedItem={setTypeSelected}
                dataType={`labelValue`}
                withSearch={false}
                withAddNew={false}
                />
          </div>
        <div className='w-full pt-5'>
            <MwButton type='secondary' onClick={filterHandler} classNameCustom='w-full' ><CiFilter size={18}/> عرض النتائج</MwButton>
        </div>
    </div>
  )
}

export default AttendanceFilter