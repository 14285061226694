import React from 'react'
import { MwSelector, TitleGroup,MwInputText } from '../ui'

const FormPrivateInfoEducation = ({
    privateInfo,
    setPrivateInfo,
    certificateLevelList,
    certificateLevelSelected,
    setCertiificationLevelSelected,
}) => {
    return (
        <div>
            <TitleGroup>التعليم</TitleGroup>
            <div className='relative flex-1'>
                <MwSelector
                    label={`مستوي التعليم`}
                    initalValue='أختار المستوي التعليمي...'
                    dataType='labelValue'
                    _data={certificateLevelList}
                    selectedItem={certificateLevelSelected}
                    setSelectedItem={setCertiificationLevelSelected}
                    withAddNew = {false}
                />
            </div>
            <div className='flex-1'>
                <MwInputText
                    label = "المجال / التخصص"
                    value = {privateInfo?.fieldOfStudy}
                    onChange = { (e) => setPrivateInfo({...privateInfo,fieldOfStudy:e.target.value})}
                    classNameInput = {`font-bold `}
                />
            </div>
            <div className='flex-1'>
                <MwInputText
                    label = "المدرسة"
                    value = {privateInfo?.schoolName}
                    onChange = { (e) => setPrivateInfo({...privateInfo,schoolName:e.target.value})}
                    classNameInput = {`font-bold `}
                />
            </div>
        </div>
    )
}

export default FormPrivateInfoEducation