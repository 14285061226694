import React from 'react'
import { AiOutlineDelete } from "react-icons/ai";
import { FaRegEdit } from "react-icons/fa";
import { BiShow } from "react-icons/bi";

const ListJobPositionItem = ({item,index,setShowConfirmDelete,setSelectedItem,setMode}) => {
    
    const deleteHandler = (item) => {
        setShowConfirmDelete(true);
        setSelectedItem(item)
    }

    const editHandler = (item) => {
        setSelectedItem(item)
        setMode('Editing')
    }

    return (
        <>
            <div className='flex gap-5 p-1 m-1 border-t border-slate-300 '>
                <div className='w-8 text-center'>{ index +1 }</div>
                <div className='flex-1'>{item.name}</div>
                <div className='flex-1'>{item?.recruiter?.name_ar || item?.recruiter?.name_en }</div>
                <div className='flex-1'>{item?.department?.name}</div>
                <div className='flex-1'>{item?.location?.name}</div>
                <div className='w-28 flex gap-3 justify-center items-center'>
                    <AiOutlineDelete size={16} onClick={()=>deleteHandler(item)} className='hover:text-red-500' />
                    <FaRegEdit size={16} onClick={()=>editHandler(item)} className='hover:text-slate-950'/>
                    {/* <BiShow size={16}/> */}
                </div>
            </div>
        </>
    )
}

export default ListJobPositionItem