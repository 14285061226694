import React from 'react'
import { MwInputText } from '../ui'

const AddNewTimeZone = ({formState,setFormState,errors}) => {
    return (
        <>
        <div className='bg-slate-50 rounded-lg text-sm p-2 pb-4'>
            <div className='text-sm font-bold text-slate-500 p-2'>
                <span> أضف منطقة توقيت جديدة </span>
            </div>
            <div className='flex-1'>
                <MwInputText
                    label="اسم المنطقة "
                    value = {formState.label}
                    onChange = { (e) => setFormState({ ...formState,label:e.target.value}) }
                    classNameInput = {`font-bold `}
                    invalid={!!errors?.label}
                    invalidMsg={errors?.label}
                />
            </div>  
        </div>
    </>
    )
}

export default AddNewTimeZone