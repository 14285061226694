import React from 'react'
import ListEmployeesItem from './ListEmployeesItem'
import { CardBasic } from '../ui'

const ListEmployees = ({data,currentLangId}) => {
    return (
        <div>
            <CardBasic>
                <div className='flex gap-5 p-2 m-1 text-slate-500 font-bold border-b-2 border-slate-300'>
                    <div className='w-12 text-center'>#</div>
                    <div className='w-16 text-center'>كود الموظف</div>
                    { currentLangId === 'en' ? <div className='flex-1 '>اسم الموظف (English)</div> :
                    <div className='flex-1 '>اسم الموظف (عربي)</div> }
                    <div className='w-32' >القسم التابع له الموظف</div>
                    <div className='w-4'></div>
                </div>

                <div className='max-h-[calc(100vh-280px)] overflow-auto '>
                    {
                        data?.map((emp,index) => (
                            <ListEmployeesItem item ={emp} key={index} index={index+1} currentLangId={currentLangId}/>
                        ))
                    }
                </div>
            </CardBasic>
            <div className='text-sm text-slate-500 px-2 pt-2'> 
                <span >عدد الموظفين : </span>
                <span className='font-bold'>{data?.length}</span>
            </div>
        </div>
    )
}

export default ListEmployees