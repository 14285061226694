import React,{useState,useEffect} from 'react'
import { RiDeleteBin5Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { MwSpinnerButton } from '../ui';

const WorkingHoursListItem = ({item,index,deleteHandler,deletingLoading,setMode,setSelectedItem}) => {
    const times = item.value && JSON.parse(item?.value) 
    const [loading,setLoading] = useState(false)

    const editItem = (item) => {
        setMode('Editing');
        setSelectedItem(item);
    }

    const deleteItem = (id) =>{
        setLoading(true)
        deleteHandler(item.id)
    }

    useEffect(() => {
        if(!deletingLoading){
            setLoading(false) 
        }
    },[deletingLoading]);

    return (
        <div className='flex border-b p-2'>
                <div className='w-16 text-center'>{index}</div>
                <div className='flex-1'>{item?.label}</div>
                <div className='flex-1 flex gap-3'>
                    <span>من</span>
                    <span>{ times && times[0]}</span>
                    <span>الي</span>
                    <span>{times && times[1]}</span>
                </div>
                <div className='w-24 flex gap-5 text-center'>
                    <FiEdit size={18} onClick={() => editItem(item)}/>
                    { ( !loading) ? 
                        <RiDeleteBin5Line size={18} onClick={()=>deleteItem(item.id)} /> : 
                        <MwSpinnerButton withLabel={false}  />
                        }
                </div>
        </div>
    )
}

export default WorkingHoursListItem