import React from 'react'
import { MwSpinner, NoDataFound } from '../ui'
import TimeZoneListItem from './TimeZoneListItem'

const TimeZoneList = ({dataList,deleteHandler,deletingLoading,setMode,setSelectedItem}) => {
    return (
        <div className='bg-slate-50 rounded-lg text-sm'>
            <div className='text-sm font-bold text-slate-500 p-2'>
                <span> قائمة مناطق التوقيت  </span>
            </div>
            <div className='flex border-b font-bold p-3'>
                <div className='w-16 text-center'>#</div>
                <div className='flex-1'>اسم المنطقة</div>
                <div className='w-24 text-center'>الإجراءات</div>
            </div>
            { 
            dataList ? dataList.length > 0 ?  
            dataList.map( (item,index) => (
                    <TimeZoneListItem 
                        key={item.id} 
                        item={item} 
                        deleteHandler={deleteHandler}
                        deletingLoading = {deletingLoading}
                        setMode = {setMode}
                        setSelectedItem = {setSelectedItem}
                        index={index+1} />
                )): <NoDataFound msg={`لا يوجد مناطق توقيت مضافة  `}/> : <MwSpinner/>
            }
        </div>
    )
}

export default TimeZoneList