import React from 'react'

const CardHeaderWithActions = ({children,title}) => {
    return (
        <>
            <div id='Header' className='flex items-center '>
                <div  className='flex-1 font-bold text-sm text-slate-400'>{title}</div>
                {children}
            </div>
            <hr className='my-3'/>
        </>
    )
}

export default CardHeaderWithActions