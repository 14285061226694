import React from 'react'
import {DataListView, DataListViewBody, DataListViewHead, DataListViewRow} from '../../components/ui/data-view/'
import {MwSpinnerButton, NoDataFound} from '../../components/ui/'
import AttendanceReportListItem from './AttendanceReportListItem'

const AttendanceReportList = ({data,loading,currentLangId}) => {
    console.log(data?.data?.length)
    return (
        <div className='flex-1'>
            {loading ? 
            <MwSpinnerButton withLabel={false} isFullCenter={true}/> :
            data?.data?.length > 0 ?
            <DataListView>
                <DataListViewHead>
                    <div className='w-20 text-center'>رقم الموظف</div>
                    <div className='flex-1'>اسم الموظف</div>
                    <div className='w-32 text-center'>تسجيل الدخول</div>
                    <div className='w-32 text-center'>  تسجيل الخروج </div>
                    <div className='w-32 text-center'> التاريخ</div>
                </DataListViewHead>
                <DataListViewBody>
                    {
                        data?.data.map(item => ( <AttendanceReportListItem key={item.id} item={item} currentLangId={currentLangId} /> ))
                    }
                </DataListViewBody>
            </DataListView> : 
            <NoDataFound msg={'لا يوجد سجلات لعرض التقرير'} />
            }
        </div>
    )
}

export default AttendanceReportList