import {React, useEffect, useState} from 'react'
import {BiChevronDown} from 'react-icons/bi'

const BasicSelector = ({
    listItem ,
    children,
    border=true,
    // setOpenList,
    // openList,
    onSelected,
    label,
    defaultValue, 
    withArrow = true,
    withChangeLabel = true,
    size = 'sm',
    className
    }) => {
    const [listLabel,setListLabel] = useState(label) 
    const [openList,setOpenList] = useState(false) 
    
    let customStyle ;
    
    if(border){
        customStyle = ` bg-slate-50 border  rounded px-3   `
    }
    
    useEffect(()=>{
        if(defaultValue && listItem){
            listItem?.map(item => {
                if( item.value === defaultValue ){
                    withChangeLabel && setListLabel(item.label)
                    onSelected(item)
                }  
            });
        }
    },[]);



    return (
        <div className={`relative flex flex-col `}>
            <div 
                onClick={ ()=>  setOpenList(!openList)}
                className={`cursor-default  ${size ==='lg' && 'h-8'} text-xs ${label && 'gap-1'} hover:text-indigo-500 flex items-center justify-between whitespace-nowrap  py-1 ${customStyle} ${ className }`} >
                { children} {label && listLabel}
                { withArrow && <BiChevronDown /> }
            </div>
            {
                listItem && 
                <ul 
                    className={`absolute w-full text-xs rounded-b-lg  top-7 z-20 right-0  primary-bg-color border-b border-r border-l border-slate-100  ${openList && listItem.length > 1  ? 'block':'hidden'} `}>
                    {
                        listItem?.map((item,index) => (
                            <li 
                                onClick={
                                    () => {
                                        onSelected(item);
                                        setOpenList(false);
                                        setListLabel(item.label);
                                    }
                                }
                                key={index}
                                className={`cursor-default  p-2 hover:bg-slate-300 hover:text-slate-700 ${listItem.length > 0 && listItem.length !== index+1 && 'border-b'}`}
                                >
                                {item.label}
                            </li>
                        ))
                    }
                </ul>
            }
        </div>
    )
}

export default BasicSelector