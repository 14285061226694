import React from 'react'
import { FaRegDotCircle } from "react-icons/fa";

const FormResumeListItem = ({item}) => {
    return (
        <div className='flex gap-3 items-center justify-between border-b p-1'>
            <div className=' flex gap-1 items-center '>
                <FaRegDotCircle className='text-slate-600' />
                <span  className='text-slate-600' >{item.title}</span>
            </div>
            <div className='flex gap-3 text-xs text-slate-400'>
                <span>{item.dateStart || item.date_start}</span>
                <span>{item.dateEnd || item.date_end}</span>
            </div>
        </div>
    )
}

export default FormResumeListItem