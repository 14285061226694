import React from 'react'

const DataListViewBody = ({offsetHeight='260',children}) => {
    return (
        <div className={`h-full flex-1 max-h-[calc(100vh-${offsetHeight}px)] overflow-auto  `}>
            {children}
        </div>
    )
}

export default DataListViewBody