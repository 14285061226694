import React,{useState,useEffect} from 'react'
import AddNewLocation from '../components/locations/AddNewLocation'
import ListLocation from '../components/locations/ListLocation';
import { useCurrent,useDelete,useFetch,usePost, usePut } from '../hooks';
import EditLocation from '../components/locations/EditLocation';
import AlertMsg from '../components/ui/AlertMsg';
import { ConfirmDelete } from '../components/ui';

const Locations = ({onHide,callback}) => {
    const {currentTenantId,currentCompanyId,currentBranchId,currentLangId,userId} = useCurrent();
    const {data:listLocationData,loading:loadinglistLocation,postData:postDataListLocation} = usePost();
    const {data:countryListData,loading:countryListLoading} = useFetch(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/countries`);
    const {data:resultPost,loading:loadingPost,postData} = usePost();
    const {data:resultPut,loading:loadingPut,putData} = usePut();
    const {data:resultDelete,loading:loadigDelete,deleteItem} = useDelete()
    const [mode,setMode] = useState('Adding')
    const [locationState,setLocationState] = useState({
        name:'',
        haveFingerprint: false,
        ipFingerprint:'',
        description:'',
        address:{
            city:'',
            region:'',
            street:'',
            postCode:'',
            lng:'',
            lat:''
        }
    });
    const [countrySelected,setCountrySelected] = useState({});
    const [countryList,setCountryList] = useState([]);

    const [errors,setErrors] = useState({});

    const [locationList,setLocationList] = useState([]);

    const [locationSelected,setLocationSelected]  = useState();

    const [onShowConfirmDelete,setOnShowConfirmDelete] = useState(false);

    const deleteHandler = () => {
        setOnShowConfirmDelete(true);
    }
    const confirmDeleteHandler = () => {
        deleteItem(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/locations/${locationSelected?.id}`)
    }
    const saveHandler = () => {
        setErrors({});
        if(!locationState.name){
            setErrors({...errors,name:'يجب ادخال الاسم'})
            console.log(errors,'name')
            return;
        }
        if(!countrySelected.value){
            setErrors({...errors,country:'يجب اختيار الدولة'})
            console.log(errors,'country')
            return;
        }
        const _data = {
            address_type : "work" ,
            address_ref_id : 0,
            address_country_id:countrySelected.value || null,
            address_city: locationState.address.city || null,
            address_region: locationState.address.region || null,
            address_street: locationState.address.street || null,
            address_post_code: locationState.address.postCode || null,
            address_lat: locationState.address.lat || null,
            address_lng: locationState.address.lng || null,
            address_description:locationState.description || null,
            location_name : locationState.name,
            location_have_fingerprint: locationState.haveFingerprint  || false,
            location_ip_fingerprint: locationState.ipFingerprint || null,
            location_description : locationState.description  || null,
            tenant_id: currentTenantId,
            company_id: currentCompanyId,
            branch_id : currentBranchId,
            created_by: userId
        }
        if(mode === 'Adding'){
            postData(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/locations/add`,_data);
        }
        if(mode === 'Editing'){
            const _id = locationSelected.id;
            _data.address_id = locationSelected.address_id
            putData(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/locations/${_id}`,_data);
        }
        
        
    }

    useEffect( () => {
        if(locationSelected && mode === 'Editing'){
            setCountrySelected({
                label:  currentLangId === 'en' ? locationSelected?.address_relation?.country_relation?.country_name_en : locationSelected?.address_relation?.country_relation?.country_name_ar,
                value : locationSelected?.address_relation?.country_relation?.id,
                lang: currentLangId
            })
            setLocationState({
                name:locationSelected.name || '',
                haveFingerprint: locationSelected?.have_fingerprint || false,
                ipFingerprint:locationSelected?.ip_fingerprint || '',
                description:locationSelected?.description || '',
                address:{
                    city:locationSelected?.address_relation?.city || '',
                    region:locationSelected?.address_relation?.region || '',
                    street:locationSelected?.address_relation?.street || '',
                    postCode:locationSelected?.address_relation?.post_code || '',
                    lng:locationSelected?.address_relation?.lng || '',
                    lat:locationSelected?.address_relation?.lat || ''
                }
            })
        }
    },[locationSelected]);

    useEffect(() =>{
        if(mode === 'Adding'){
            setLocationState({
                name:'',
                haveFingerprint: false,
                ipFingerprint:'',
                description:'',
                address:{
                    city:'',
                    region:'',
                    street:'',
                    postCode:'',
                    lng:'',
                    lat:''
                }
            });
        }
    },[mode])
    useEffect(() =>{
        if(resultDelete){
            setOnShowConfirmDelete(false)
        }
    },[resultDelete]);

    useEffect(() => {
        const _data = {
            tenant_id:currentTenantId,
            company_id:currentCompanyId,
            branch_id:currentBranchId
        };
        postDataListLocation(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/locations`,_data);
    },[resultPost,resultPut,loadigDelete]);

    useEffect(() => {
        if(listLocationData){
            setLocationList(listLocationData.data);
        }
    },[listLocationData]);

    useEffect(() => {
        if(countryListData && !countryListLoading){
            console.log('countryListData',countryListData);
            const _list= countryListData?.data.map(country => ({
                label: country.country_name_ar,
                value:country.id,
                lang: 'ar'
            }));
            setCountryList(_list);
        }
    },[countryListData]);

    useEffect(() => {
        if(resultPost && !loadingPost && onHide){
            callback();
            onHide();
        }
    },[resultPost]);

    return (
        <div className='flex flex-col gap-3'>
            { resultPut && resultPut.status === 1 && <AlertMsg>تم التعديل بنجاح</AlertMsg> }
            { resultPost && resultPost.status === 1 && <AlertMsg>تمت الاضافة بنجاح</AlertMsg> }
            <ConfirmDelete 
                title={`هل تريد حذف الموقع`}
                msg={`هل تريد حذف الموقع`}
                onShow={onShowConfirmDelete}
                setOnShow={setOnShowConfirmDelete}
                onDeleteHandler={confirmDeleteHandler}
                />
            { mode === 'Adding' ? 
                <AddNewLocation 
                    onHide={onHide}
                    locationState = {locationState}
                    setLocationState = {setLocationState}
                    saveHandler={saveHandler}
                    countrySelected = {countrySelected}
                    setCountrySelected = {setCountrySelected}
                    countryList = {countryList}
                    errors={errors}
                />: <EditLocation
                    locationSelected = {locationSelected}
                    mode={mode}
                    setMode={setMode}
                    onHide={onHide}
                    locationState = {locationState}
                    setLocationState = {setLocationState}
                    saveHandler={saveHandler}
                    countrySelected = {countrySelected}
                    setCountrySelected = {setCountrySelected}
                    countryList = {countryList}
                    errors={errors}
                />
            }
            <ListLocation 
                data={locationList}                     
                deleteHandler = {deleteHandler}
                setLocationSelected = {setLocationSelected} 
                setMode = {setMode} 
                loading={loadinglistLocation} 
                />
        </div>
    )
}

export default Locations