import React,{ useState} from 'react'
import { useDispatch } from 'react-redux'

import BasicSelector from '../components/ui/BasicSelector.jsx'

import {setCurrentCompany} from '../store/currentCompanySlice.js'
import {setCurrentBranch} from '../store/currentBranchSlice.js'

// ICONS
import {MdOutlineHomeWork} from 'react-icons/md'
import {AiOutlineBranches} from 'react-icons/ai'

const TenantBar = ({companiesList,branchesList,companySelected,branchSelected}) => {
    
    const dispatch = useDispatch();
    const [openListCompanies,setOpenListCompanies] = useState(false);
    const [openListBranches,setOpenListBranches] = useState(false);


        
    const onSelectedCompany = (company) => {
        // console.log('Company select :',company);
        dispatch(setCurrentCompany({name:company.label,value:company.value}));
    }

    const onSelectedBranch = (branch) => {
        // console.log('Selected Branch',branch);
        dispatch(setCurrentBranch({name:branch.label,value:branch.value}));
    }
    // console.log('TenantBar is Ready',companiesList,branchesList);
    return (
        <div className='flex-1 flex items-center text-xs ps-2 ' >
            <BasicSelector 
                border={false}
                label={companySelected.name}
                defaultValue={companySelected.name}
                listItem={companiesList}
                openList={openListCompanies}
                setOpenList={setOpenListCompanies}
                onSelected={onSelectedCompany}
            >
                <MdOutlineHomeWork/>
            </BasicSelector>
            <div className='text-slate-200 px-2'>/</div>
            <BasicSelector 
                border={false}
                label={branchSelected.name}
                defaultValue={branchSelected.name}
                listItem={branchesList}
                openList={openListBranches}
                setOpenList={setOpenListBranches}
                onSelected={onSelectedBranch}
            >
                <AiOutlineBranches/>
            </BasicSelector>
        </div>
    )
}

export default TenantBar