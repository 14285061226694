import React from 'react'

const MwTabViewHead = ({tabTitleList=[],tabActive,setTabActive}) => {
    return (
        <div className='flex text-sm text-slate-600 '>
            {
                tabTitleList.map((tab,index) =>(
                    <div key={index} onClick={() =>setTabActive(tab.value) } className={`cursor-default px-3 py-2 ml-1 bg-slate-200 rounded-t-xl ${tab.value === tabActive && 'font-bold'}`}> {tab.label}</div>
                ))
            }
        </div>
    )
}

export default MwTabViewHead