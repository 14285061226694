import React from 'react'
import ListJobPositionItem from './ListJobPositionItem'

const ListJobPosition = ({data,setShowConfirmDelete,setSelectedItem,mode,setMode}) => {
    return (
        <div className='px-3'>
            <div className='text-sm font-bold text-slate-500 p-2'>
                <span>قائمة الوظائف</span>
            </div>
            {
                data && 
                <div className='text-xs rounded-xl bg-slate-200 p-3  '>
                    <div className='flex gap-5  '>
                        <div className='w-8 text-center'>#</div>
                        <div className='flex-1'>اسم الوظيفة</div>
                        <div className='flex-1'>مسئول التوظيف</div>
                        <div className='flex-1'>القسم</div>
                        <div className='flex-1'>الموقع/المكان</div>
                        <div className='w-28 text-center'>الإجراءات</div>
                    </div>
                    <div>
                        {
                            data.data?.map((jobPosition,index) => (
                                <ListJobPositionItem key={jobPosition.id} index={index} item={jobPosition} setShowConfirmDelete={setShowConfirmDelete} setSelectedItem={setSelectedItem} setMode={setMode} />
                            ))
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default ListJobPosition