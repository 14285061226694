import React,{useEffect} from 'react'
import { useCurrent, usePost } from '../../hooks';
import { MwSpinnerButton, NoDataFound } from '../ui';
import { AiOutlineDelete } from "react-icons/ai";
import { FaRegEdit } from "react-icons/fa";
import { BiShow } from "react-icons/bi";


const ListDepartments = ({callback,reload,setShowConfirmDelete,setSelectedItem,setMode}) => {
    const {currentTenantId,currentCompanyId,currentBranchId} = useCurrent();
    const {data:resultDepartmentsData,loading:loadingDepartments,postData:postDataDepartments} = usePost();

    const init = async() => {
        const _data = {
            tenant_id:currentTenantId,
            company_id:currentCompanyId,
            branch_id:currentBranchId
        }
        if(currentTenantId && currentCompanyId && currentBranchId){
           // if(!loadingDepartments && !resultDepartmentsData){
                await postDataDepartments(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/departments`,_data);
          //  }
        }
    }

    useEffect(() =>{
        console.log('reload => ',reload)
        init();
    },[reload]);

    

    const deleteHandler = (item) => {
        setSelectedItem(item)
        setShowConfirmDelete(true);
    }

    const editHandler = (item) => {
        console.log('edit => ',item)
        setSelectedItem(item)
        setMode('Editing');
    }
    return (
        <div className='w-full p-4 '>
            <div className='text-sm font-bold text-slate-500 p-2'>
                <span>قائمة الاقسام</span>
            </div>
            { 
                loadingDepartments ? <MwSpinnerButton isFullCenter={true} /> :
                resultDepartmentsData?.data?.length > 0 ? 
                    <div className='text-xs rounded-xl bg-slate-200'>
                        <div className=' font-bold w-full p-3 bg-slate-300 flex gap-7 justify-between  items-center rounded-t-2xl'>
                            <div className='w-8  text-center'>#</div>
                            <div className='w-48'>اسم القسم</div>
                            <div className='flex-1'>القسم الرئيسي</div>
                            <div className='w-48 '>مدير القسم</div>
                            <div className='w-20 text-center'>عدد الموظفين</div>
                            <div className='w-28 text-center'>الإجراءات</div>

                        </div>
                        {
                            resultDepartmentsData && resultDepartmentsData.data.map((department,index) => (
                                <div key={department.id} className=' w-full flex justify-between items-center gap-7 p-2 border-t border-slate-300 cursor-default hover:font-bold hover:bg-slate-300'>
                                    <div className='w-8 text-center'>{index + 1}</div>
                                    <div className='w-48 flex flex-reverse'>
                                        <span>{department.name}</span>
                                    </div>
                                    <div className='flex-1'>{`${department.parent_relation?.parent_relation?.parent_relation?.name ? department.parent_relation?.parent_relation?.parent_relation?.name+'/':'' } ${department.parent_relation?.parent_relation?.name ? department.parent_relation?.parent_relation?.name+'/' : ''} ${department.parent_relation?.name || 'قسم رئيسي'}`}</div>
                                    <div className='w-48'>{`${department.manager_relation?.name_en || 'لم يتم تعين مدير للقسم'}`}</div>
                                    <div className='w-20 text-center'> {department.employees_count} </div>
                                    <div className='w-28 flex gap-3 justify-center items-center'>
                                        <AiOutlineDelete className='hover:font-bold hover:text-red-500' size={16} onClick={()=>deleteHandler(department)} />
                                        <FaRegEdit className='hover:font-bold hover:text-slate-950' size={16} onClick={()=>editHandler(department)}/>
                                        {/* <BiShow size={16}/> */}
                                    </div>
                                </div>
                            ))
                        }
                    </div> : <NoDataFound msg={`لا يوجد اقسام `}/>
            }
        </div>
    )
}

export default ListDepartments