import React, { useEffect,useState } from 'react'
import { MwButton, MwSelector } from '../ui'

const AddNewSkill = ({
        dataList,
        currentLangId,
        typeSelected,
        setTypeSelected,
        skillSelected,
        setSkillSelected,
        levelSelected,
        setLevelSelected,
        list,
        setList

    }) => {

    const [skillsList,setSkillsList] = useState([]);
    const [levelsList,setLevelsList] = useState([]);

    useEffect(()=> {
        if(typeSelected.value){
            const _listSkills = dataList?.map( (item) => {                     
                if(item.id === typeSelected.value ){
                    return item.skills.map(skill => ({label:skill.title, value:skill.id, lang:currentLangId }))
                }  
            })
            setSkillsList(..._listSkills);

            const _listLevels = dataList?.map( (item) => {                     
                if(item.id === typeSelected.value ){
                    return item.levels.map(level => ({label:`${level.title} (${level?.level_percent}%)` , progress:level?.level_percent, value:level.id, lang:currentLangId }))
                }  
            })
            setLevelsList(..._listLevels);
        }
    },[typeSelected])

    const addHandler = () =>{
        setList([...list,{
            type : typeSelected,
            skills:skillSelected,
            levels:levelSelected,
        }]);
    }

    return (
        <>
            <div className='flex  gap-1 items-end'>
                <div className='flex-1'>
                    <MwSelector
                        label = 'اختار نوع المهارة'
                        _data={dataList.map((item) =>({label: item.name, value:item.id,lang:currentLangId}))}
                        dataType='labelValue'
                        withAddNew = {false}
                        selectedItem={typeSelected}
                        setSelectedItem={setTypeSelected}
                        />
                </div>
                {
                    skillsList.length > 0 && <div className='flex-1'>
                        <MwSelector
                            label = 'اختار المهارة'
                            _data= {skillsList}
                            dataType='labelValue'
                            withAddNew = {false}
                            selectedItem={skillSelected}
                            setSelectedItem={setSkillSelected}
                            />
                    </div>
                }
                {
                    levelsList.length > 0 && <div className='flex-1'>
                        <MwSelector
                            label = 'اختار المستوي'
                            _data= {levelsList}
                            dataType='labelValue'
                            withAddNew = {false}
                            selectedItem={levelSelected}
                            setSelectedItem={setLevelSelected}
                            />
                    </div>
                }
                <MwButton type='secondary-reverse' inGroup={true} onClick={(e)=>addHandler(e)}>أضف</MwButton>

            </div>
        </>
    )
}

export default AddNewSkill