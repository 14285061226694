import React from 'react'
import { MwButton, NoDataFound } from '../ui'
import {BiMessageSquareAdd} from 'react-icons/bi'
import FormResumeListItem from './FormResumeListItem'
import SkillsListItem from '../skills/SkillsListItem'

const FormResume = ({
    setShowResumeLines,
    resumeLinesList,
    setShowSkills,
    skillsList,
    setSkillsList
}) => {
    const listbyType = (typeName) => {
        return resumeLinesList.filter(item => item.type === typeName)
    }
    const addNewResumeLine = () => {
        setShowResumeLines(true);
    };
    const addNewSkill = () => {
        setShowSkills(true);
    };
    
    return (
        <div className='px-3 '>
            <div className='flex gap-12 justify-between items-start '>
                <div className=' flex-1 flex flex-col jsutify-between '>
                    <div className='border-b border-slate-200 pb-2 flex justify-between items-center'>
                        <span>الدورات التدريبة والخبرات</span>
                        <MwButton actionType='button' withIcon={false} type='saveBtn' size='sm' onClick={addNewResumeLine}  ><BiMessageSquareAdd/>أنشئ جديد</MwButton>
                    </div>
                    {
                    listbyType('experience').length> 0 && <div className=' text-sm' >
                        <div className='py-2 font-bold'>الخبرات</div>
                        <ul className='px-3'>
                            {
                                listbyType('experience').map((item,index) => <FormResumeListItem key={index} item={item}/> )
                            }
                        </ul>
                    </div>
                    }
                    { listbyType('education').length > 0 &&
                        <div className=' text-sm pt-3' >
                                <div className='py-2 font-bold'>التعليم</div>
                            <ul className='px-3'>
                                {
                                    listbyType('education').map((item,index) => <FormResumeListItem key={index} item={item}/> )
                                }
                            </ul>
                        </div>
                    }
                    {/* {
                        (listbyType('education').length === 0 && listbyType('experience').length === 0)  && 
                        <NoDataFound msg={` لا يوجد خبرات / تعليم - تدريب`} /> 
                    } */}
                </div>
                <div className='flex-1  '>
                    <div className='border-b border-slate-200 pb-2 flex justify-between items-center'>
                        <span>المهارات</span>
                        <MwButton actionType='button' withIcon={false} type='saveBtn' size='sm' onClick={addNewSkill}  ><BiMessageSquareAdd/>أنشئ جديد</MwButton>
                    </div>
                    <div>
                        {
                            skillsList.map((skill,index) => (
                            <div key={index} className='flex gap-3 text-xs items-center justify-between border-b py-2'>
                                <div className='flex-1'>{skill?.skill_type_id?.label || skill?.type?.label  }</div>
                                <div className='flex-1'>{skill?.skill_id?.label || skill?.skills?.label }</div>
                                <div className='flex-1 text-xs'>
                                    <div className='text-center '>{skill?.skill_level_id?.label || skill?.levels?.label}</div>
                                    <div className='flex-1 bg-slate-300 rounded-lg'>
                                    { (skill?.skill_level_id?.progress || skill?.levels?.progress) && <div className={`bg-slate-400 ${(skill?.skill_level_id?.progress || skill?.levels?.progress) > 98 ? 'rounded-lg' : 'rounded-r-lg'}  text-center text-slate-50`} style={{ width: skill?.skill_level_id?.progress || skill?.levels?.progress+"%" }}>{skill?.skill_level_id?.progress || skill?.levels?.progress}%</div> }
                                    </div>
                                </div>
                            </div>))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormResume