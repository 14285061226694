import React from 'react'
import { DataListViewRow } from '../ui/data-view'

const AttendanceReportListItem = ({item,currentLangId}) => {
    const name = currentLangId === 'en' ? item.emp_name_en || item.emp_name_ar  : item.emp_name_ar || item.emp_name_en 
    return (
        <DataListViewRow >
            <div className='w-20 text-center'>{item.emp_no}</div>
            <div className='flex-1'>{name || 'غير مسجل في النظام'}</div>
            <div className='w-32 text-center font-bold'> { (item.checkin).substring(10,20)} </div>
            <div className='w-32 text-center font-bold'> { (item.checkout).substring(10,20)} </div>
            <div className='w-32 text-center '> {item.date} </div>
        </DataListViewRow>
    )
}

export default AttendanceReportListItem