import React from 'react'
import { MwInputText, TitleGroup } from '../ui'
import MwInputFile from '../ui/MwInputFile'
import { Link } from 'react-router-dom'

const FormPrivateInfoWorkPermit = ({
    privateInfo,
    setPrivateInfo,
    handlePermitFileUpload,
    loadingPermitFile,
    selectedPermit
}) => {
    return (
        <div>
            <TitleGroup>تصريح العمل</TitleGroup>
            <div className='flex gap-5'>
                <div className='flex-1'>
                    <MwInputText
                        label="رقم التاشيرة"
                        value = {privateInfo?.visaNo}
                        onChange = { (e) => setPrivateInfo({...privateInfo,visaNo:e.target.value})}
                        classNameInput = {`font-bold `}
                    />
                </div>
                <div className='flex-1'>
                    <MwInputText
                        label="رقم تصريح العمل"
                        value = {privateInfo?.visaPermitNo}
                        onChange = { (e) => setPrivateInfo({...privateInfo,visaPermitNo:e.target.value})}
                        classNameInput = {`font-bold `}
                    />
                </div>
            </div>
            <div className='flex gap-5'>
                <div className='flex-1'>
                    <MwInputText
                        inputType='date'
                        label="تاريخ انتهاء التاشيرة"
                        value = {privateInfo?.visaExpireDate}
                        onChange = { (e) => setPrivateInfo({...privateInfo,visaExpireDate:e.target.value})}
                        classNameInput = {`font-bold `}
                    />
                </div>
                <div className='flex-1'>
                    <MwInputText
                        inputType='date'
                        label="تاريخ انتهاء تصريح العمل"
                        value = {privateInfo?.workPermitExpireDate}
                        onChange = { (e) => setPrivateInfo({...privateInfo,workPermitExpireDate:e.target.value})}
                        classNameInput = {`font-bold `}
                    />
                </div>
            </div>
            <div className='flex gap-5 justify-between items-center pt-2 text-xs'>
                <MwInputFile
                    label={`تصريح العمل :`}
                    acceptTypes='application/pdf,application/msword' 
                    onChangeHandler={handlePermitFileUpload}
                    loading={loadingPermitFile}
                    />
                { selectedPermit && <div className='text-left text-xs'><Link target='_blank' className='text-indigo-600 rounded-xl bg-slate-300 px-3 py-2 font-bold' to={selectedPermit}> DOWNLOAD FILE </Link></div> }
            </div>
        </div>
    )
}

export default FormPrivateInfoWorkPermit