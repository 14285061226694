import React from 'react'

const DataListView = ({children}) => {
    return (
        <div className='text-xs text-slate-600 flex flex-col justify-between items-between'>
            {children}
        </div>
    )
}

export default DataListView