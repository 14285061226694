import React from 'react' 
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// React Icons
import {MdOutlineAddBox} from 'react-icons/md'
import {TbFileInvoice} from 'react-icons/tb'
import {FiSettings} from 'react-icons/fi'
import {AiOutlineDashboard} from 'react-icons/ai'
import {LuLayoutDashboard} from 'react-icons/lu'
import {HiOutlineDocumentReport} from 'react-icons/hi'
import {PiUsersThreeThin} from 'react-icons/pi'
import {LiaUsersCogSolid,LiaUsersSolid} from 'react-icons/lia'
import {RiQuoteText} from 'react-icons/ri'
import { TbTournament } from "react-icons/tb";

// Custom hooks
import { useTenant } from '../hooks'

const MenuSide = ({openMenu,setOpenMenu}) => {
    const [t] = useTranslation('global')
    const {tenantUsername} = useTenant();
    return (
        <div 
            // onMouseOver={()=>setOpenMenu(true)}
            className={`  z-50   flex items-center   transition-all duration-500  ease-out  h-screen      primary-bg-color border-b  border-slate-700   ${openMenu ? 'fixed px-3 w-9 flex md:w-40 md:relative ' : 'fixed w-0 hidden md:flex md:w-12 md:px-3  '}`}>
                <ul className=' cursor-default  flex  flex-col gap-5 items-center  text-white  text-xs  '>
                    <li className={`w-full   border-indigo-900  border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}`} className={`flex items-center gap-2 `} end >
                            <AiOutlineDashboard size={20}  title={t('menu.Dashboard')} />
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'>{t('menu.Dashboard')}</span>}
                        </NavLink>
                    </li>
                    <li className={`w-full border-indigo-900 border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/employees/addNewEmployee`} className={`flex items-center gap-2 `} end >
                            <MdOutlineAddBox size={20} title="أضف موظف جديد" />
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'>أضف موظف جديد</span>}
                        </NavLink>
                    </li>
                    <li className={` w-full gap-2 flex items-center  border-indigo-900  border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/employees`} className={`flex items-center gap-2 `} end >
                            <TbFileInvoice size={20} title={`إدارة الموظفين`}/>
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'>{`إدارة الموظفين`}</span>}
                        </NavLink>
                    </li>
                    <li className={`w-full border-indigo-900  border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/attendance`} className={`flex items-center gap-2 `} end >
                            <RiQuoteText size={20} title='ادارة الحضور والانصراف'  />
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'> الحضور والانصراف </span>}
                        </NavLink>
                    </li>
                    <li 
                        className={` w-full gap-2 flex items-center  border-indigo-900  border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/departments`} className={`flex items-center gap-2 `} end >
                            <TbTournament size={20} title={`الاقسام والادارات`}/>
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'> الاقسام / الإدارات </span>}
                        </NavLink>
                    </li>
                    <li 
                        className={` w-full gap-2 flex items-center  border-indigo-900  border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/job-position`} className={`flex items-center gap-2 `} end >
                            <LuLayoutDashboard size={20} title={`ادارة الوظائف`}/>
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'> ادارة الوظائف </span>}
                        </NavLink>
                    </li>
                    {/* <li 
                        className={` w-full gap-2 flex items-center  border-indigo-900  border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/`} className={`flex items-center gap-2 `} end >
                            <LiaUsersCogSolid size={20} title={``} />
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'></span>}
                        </NavLink>
                    </li> */}
                    {/* <li 
                        className={` w-full gap-2 flex items-center  border-indigo-900  border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/salesmans`} className={`flex items-center gap-2 `} end >
                            <LiaUsersSolid size={20} title={''} />
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'></span>}
                        </NavLink>
                    </li> */}
                    {/* <li 
                        className={` w-full gap-2 flex items-center  border-indigo-100/50  border-b pb-1 hover:text-indigo-600 ${ openMenu || 'justify-center' } `}>
                        <CiDiscount1 size={20} title={t('menu.Promotions')}/>
                        { openMenu && <span className='whitespace-nowrap hidden md:flex'>{t('menu.Promotions')}</span>}
                    </li> */}
                    <li 
                        className={` w-full gap-2 flex items-center  border-indigo-900  border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/reports`} className={`flex items-center gap-2 `} end >
                            <HiOutlineDocumentReport size={20} title={'التقارير'} />
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'>{'التقارير'}</span>}
                        </NavLink>
                    </li>
                    <li 
                        className={` w-full gap-2 flex items-center  border-indigo-900  border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/settings`} className={`flex items-center gap-2 `} end >
                            <FiSettings size={20} title={t('menu.Settings')}/>
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'> إعدادات النظام </span>}
                        </NavLink>
                    </li>
                    <li 
                        className={` w-full gap-2 flex items-center  border-indigo-900  border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                        <PiUsersThreeThin size={20} title={t('menu.Users')}/>
                        { openMenu && <span className='whitespace-nowrap hidden md:flex'>{t('menu.Users')}</span>}
                    </li>
                </ul>
        </div>
    )
}

export default MenuSide