export const attendanceTypes = [
    {
        label:'ALL',
        value: null,
        lang:'en'
    },
    {
        label:'عرض الجميع',
        value: null,
        lang:'ar'
    },
    {
        label:'CheckIn',
        value:'checkIn',
        lang:'en'
    },
    {
        label:'CheckIn',
        value:'checkIn',
        lang:'ar'
    },
    {
        label:'CheckOut',
        value:'checkOut',
        lang:'en'
    },
    {
        label:'CheckOut',
        value:'checkOut',
        lang:'ar'
    },
]