import React, { useState } from 'react'
import {  useTranslation } from 'react-i18next';

import {MwButton, MwInputText} from '../ui/';
import {BiMessageSquareAdd} from 'react-icons/bi'
import TagListItem from './TagListItem';

const TagList = ({tagList,setTagList,withLabel=false,label}) => {
    const [t] = useTranslation('global')
    const [inputTag,setInputTag] = useState('');

    const removeTag = (tag) => {
        const tagFilter =  tagList.filter(t => t !== tag); 
        setTagList(tagFilter)
    }
    const addTag = () =>{
        if(!inputTag) return;
        tagList?.includes(inputTag) ||  setTagList([...tagList , inputTag])
        setInputTag('')
    }
    const onKeyDown = (event) =>{
        if (event.key === 'Enter') {
            addTag()
        }
    }

    const tags = tagList?.map((tag,index) => ( <TagListItem key={index} tag={tag} removeTagHandler={()=>removeTag(tag)} />))

    return (
        <div className='flex-1 '>
            {withLabel && <label className='text-xs text-slate-400'>{label}</label>}
            <div className='flex flex-col items-center  text-xs bg-white rounded h-8 relative'>
                <MwInputText
                    onKeyDown = {onKeyDown}
                    placeholder={t('invoice.enterTagName')}
                    value={inputTag}
                    onChange= {(e)=> setInputTag(e.target.value) }
                    />
                <div className='absolute end-1 top-1 '>
                    <MwButton actionType='button' withIcon={false} type='saveBtn' size='sm' onClick={addTag}  ><BiMessageSquareAdd/></MwButton>
                </div>
                <div className='flex overflow-x-auto w-full text-slate-400 absolute left-0 -bottom-3'>
                    {tags}
                </div>
            </div>
        </div>
    )
}

export default TagList