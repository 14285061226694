import React from 'react'

const DataListViewHead = ({children}) => {
    return (
        <div className='h-8 flex gap-2 items-center justify-between bg-slate-300 p-3 rounded-t-xl'>
            {children}
        </div>
    )
}

export default DataListViewHead