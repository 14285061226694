import React,{useState,useEffect} from 'react'
import { RiDeleteBin5Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { MwSpinnerButton } from '../ui';

const ResumeLinesListItem = ({item,index,deleteHandler,setMode,setItemSelected}) => {
    const [loading,setLoading] = useState(false)

    const editItem = (item,index) => {
        setItemSelected({...item,index})
        setMode('Editing');
    }

    const deleteItem = (index) =>{
        setLoading(true)
        deleteHandler(index)
        setLoading(false)
    }


    return (
        <div className='flex border-b p-2 text-xs'>
            <div className='w-16 text-center'>{index+1}</div>
            <div className='flex-1'>{item.title}</div>
            <div className='flex-1 text-xs'>{item.type }</div>
            <div className='flex-1'> {item.dateStart} </div>
            <div className='flex-1'> {item.dateEnd} </div>
            <div className='w-20 flex gap-5 text-center'>
                    <FiEdit size={16} onClick={() => editItem(item,index)}/>
                    { ( !loading) ? 
                        <RiDeleteBin5Line size={16} onClick={()=>deleteItem(index)} /> : 
                        <MwSpinnerButton withLabel={false}  />
                        }
                </div>
        </div>
    )
}

export default ResumeLinesListItem