import React from 'react'
import { MwSelector, TitleGroup,MwInputText } from '../ui'

const FormPrivateInfoMaritalStatus = ({
    privateInfo,
    setPrivateInfo,
    maritalStatusList,
    maritalStatusSelected,
    setMaritalStatusSelected
}) => {
    return (
        <div>
            <TitleGroup>الحالة الاجتماعية</TitleGroup>
            <div className='flex gap-5'>
                <div className='relative flex-1'>
                    <MwSelector
                        label={`الحالة الإجتماعية`}
                        initalValue='أختار الحالة الاجتماعية...'
                        dataType='labelValue'
                        _data={maritalStatusList}
                        selectedItem={maritalStatusSelected}
                        setSelectedItem={setMaritalStatusSelected}
                        withAddNew = {false}
                    />
                </div>
                <div className='flex-1'>
                    <MwInputText
                        label = "عدد الاطفال"
                        inputType = "number"
                        value = {privateInfo?.numberOfChildren}
                        onChange = { (e) => setPrivateInfo({...privateInfo,numberOfChildren:e.target.value})}
                        classNameInput = {`font-bold `}
                    />
                </div>
            </div>
        </div>
    )
}

export default FormPrivateInfoMaritalStatus