import React from 'react'
import WorkingHoursListItem from './WorkingHoursListItem'
import { MwSpinner, NoDataFound } from '../ui'

const WorkingHoursList = ({workingHoursList,deleteHandler,deletingLoading,setMode,setSelectedItem}) => {

    return (
        <div className='bg-slate-50 rounded-lg text-sm'>
            <div className='text-sm font-bold text-slate-500 p-2'>
                <span> قائمة اوقات الدوام / اوقات العمل </span>
            </div>
            <div className='flex border-b font-bold p-3'>
                <div className='w-16 text-center'>#</div>
                <div className='flex-1'>اسم الدوام</div>
                <div className='flex-1'> الفترة</div>
                <div className='w-24 text-center'>الإجراءات</div>
            </div>
            { 
            workingHoursList ? workingHoursList.length > 0 ?  
                workingHoursList.map( (item,index) => (
                    <WorkingHoursListItem 
                        key={item.id} 
                        item={item} 
                        deleteHandler={deleteHandler}
                        deletingLoading = {deletingLoading}
                        setMode = {setMode}
                        setSelectedItem = {setSelectedItem}
                        index={index+1} />
                )): <NoDataFound msg={`لا يوجد اوقات دوام `}/> : <MwSpinner/>
            }
        </div>
    )
}

export default WorkingHoursList