import React from 'react'
import {   MwSelector } from '../ui'
import TitleGroup from '../ui/TitleGroup'

const FormWorkInfoTab = ({
    locationList,
    locationSelected,
    setLocationSelected,
    setShowLocationModal,
    employeesList,
    approversExpenseSelected,
    setApprovalsExpenseSelected,
    approversTimeoffSelected,
    setApprovalsTimeoffSelected,
    workingHoursList,
    workingHoursSelected,
    setWorkingHoursSelected,
    timezoneList,
    timezoneSelected,
    setTimezoneSelected,
    showWorkingHoursModal,
    setShowWorkingHoursModal,
    showTimezoneModal,
    setShowTimezoneModal

}) => {
    return (
        <div className='px-3 flex flex-col gap-3'>
            <TitleGroup>موقع العمل</TitleGroup>
            <div className='relative flex-1'>
            <MwSelector
                                label={`مكان / موقع الوظيفة`}
                                dataType='labelValue'
                                initalValue='أختار مكان/موقع الوظيفة...'
                                _data={locationList}
                                selectedItem={locationSelected}
                                setSelectedItem={setLocationSelected}
                                // withAddNew ={false}
                                onClickNew={()=>setShowLocationModal(true)}
                            />
            </div>
            <div>
                <TitleGroup >الاعتماد </TitleGroup>
                <div className='flex gap-7'>
                    <div className='relative flex-1'>
                        <MwSelector
                            label={`المسئول عن اعتماد المصروفات`}
                            initalValue = {`اختار المسئول عن اعتماد المصروفات`}
                            dataType='labelValue'
                            selectedItem={approversExpenseSelected}
                            setSelectedItem={setApprovalsExpenseSelected}
                            _data={employeesList}
                            withAddNew={false}
                        />
                    </div>
                    <div className='relative flex-1'>
                        <MwSelector
                            label={`المسئول عن اعتماد الاجازات`}
                            initalValue={`اختار المسئول عن اعتماد الاجازات`}
                            dataType='labelValue'
                            _data={employeesList}
                            selectedItem={approversTimeoffSelected}
                            setSelectedItem={setApprovalsTimeoffSelected}
                            withAddNew={false}
                        />
                    </div>
                </div>
            </div>
            <div>
                <TitleGroup>اوقات العمل</TitleGroup>
                <div className='flex gap-7'>
                    <div className='relative flex-1'>
                        <MwSelector
                            label={`ساعات العمل`}
                            initalValue={`اختار ساعات العمل`}
                            dataType='labelValue'
                            _data={workingHoursList}
                            selectedItem={workingHoursSelected}
                            setSelectedItem={setWorkingHoursSelected}
                            onClickNew = { () => setShowWorkingHoursModal(true)}
                        />
                    </div>
                    <div className='relative flex-1'>
                        <MwSelector
                            label={`المنطقة الزمنية`}
                            initalValue={`اختار المنطقة الزمنية`}
                            dataType='labelValue'
                            _data={timezoneList}
                            selectedItem={timezoneSelected}
                            setSelectedItem={setTimezoneSelected}
                            onClickNew={ () => setShowTimezoneModal(true)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormWorkInfoTab