import React,{ useEffect, useState} from 'react'
import AddNewDepartment from '../components/department/AddNewDepartment'
import ListDepartments from '../components/department/ListDepartments'
import { ConfirmDelete } from '../components/ui'
import { useDelete } from '../hooks'
import EditDepartment from '../components/department/EditDepartment'

const ManageDepartment = ({onHide,callback}) => {
    
    const [reload,setReload] = useState(false)
    const [mode,setMode] = useState('Adding')
    const [showConfirmDelete,setShowConfirmDelete] = useState(false)
    const [selectedItem,setSelectedItem] = useState()
    const {data:deleteResult,loading:deleteLoading,deleteItem} = useDelete()

    const deleteHandler = () =>{
        console.log('delete',selectedItem)
        deleteItem(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/departments/${selectedItem.id}`)
    }
    useEffect(()=>{
        if(deleteResult){
            setReload(!reload)
            setShowConfirmDelete(false)
            setSelectedItem(null)
        }
    },[deleteResult])



    return (
        <div>
            <ConfirmDelete 
                title={'حذف قسم'}
                msg={'هل انت متاكد من حذف القسم'}
                onShow={showConfirmDelete}
                setOnShow={setShowConfirmDelete}
                loading={deleteLoading}
                onDeleteHandler={deleteHandler}
                />
            { mode === 'Adding' && <AddNewDepartment onHide={onHide} callback={callback} reload={reload} setReload={setReload}/> }
            { mode === 'Editing' && selectedItem && <EditDepartment item={selectedItem} onHide={onHide} callback={callback} setReload={setReload} setMode={setMode} /> }
            <ListDepartments reload ={reload} setMode={setMode} setShowConfirmDelete={setShowConfirmDelete} setSelectedItem={setSelectedItem}/>
        </div>
    )
}

export default ManageDepartment