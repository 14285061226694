import {useState} from 'react'
import {  useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import BasicSelector from '../components/ui/BasicSelector.jsx'
import { useSelector,useDispatch } from 'react-redux'
import { setLang } from '../store/langSlice.js'
import {useUser} from '../hooks/'

// ICONS 
import {MdLanguage} from 'react-icons/md'
import {VscAccount} from 'react-icons/vsc'
import {MdNotificationsNone,MdSupport} from 'react-icons/md'

const UserBar = ({tenantUsername,tenant}) => {
    const [t,i18n] = useTranslation('global')
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {getUser} = useUser();
    const _token = localStorage.getItem('token');
    // Lang List
    const [openListLang,setOpenListLang] = useState(false);
    const listLangHandler = tenant?.languages?.map(language => ({label:language.langName,value:language.langCode}))
    const langSelected = useSelector((state)=>state.lang)

    const logoutHandler = () => {
        console.log('logout')
        navigate(`/${tenantUsername}/logout`)
    }
    const onSelectedLangHandler = (lang) => {
        i18n.changeLanguage(lang.value)
        dispatch(setLang({label:lang.label,value:lang.value}))
    }


    const onSelectedUserList = (item) => {
        // console.log(value)
        switch (item.value) {
            case 'Logout':
                logoutHandler()
                break;
            default:
                break;
        }
    }

    // User List
    const [openListUser,setOpenListUser] = useState(false);
    const userList = [
        {label:'My Account',value:'MyAccount'},
        {label:'Change Password',value:'ChangePassword'},
        {label:'Logout',value:'Logout'},
    ]
    return (
        <div className='flex items-center justify-between md:justify-end gap-2 text-xs  w-full '>

            <BasicSelector 
                border={false}
                listItem={userList}
                label={getUser?.name }
                defaultValue={getUser?.name }
                openList={openListUser}
                setOpenList={setOpenListUser}
                withChangeLabel={false}
                onSelected={onSelectedUserList}
                >
                    <VscAccount size={14}/> 
                </BasicSelector>

            <div className='text-slate-200'>|</div>
            
            <BasicSelector withArrow={false}  border={false} > <MdNotificationsNone size={16} /></BasicSelector>
            
            <div className='text-slate-200'>|</div>
            
            <Link  className='px-3' target='_blank'  to={`${process.env.REACT_APP_WEB_SUPPORT_BASE_URL}/${tenantUsername}/token/${_token}`}> <MdSupport size={16} /> </Link>
            
            <div className='text-slate-200'>|</div>

            <BasicSelector 
                border={false}
                label={`Select Language`}
                openList = {openListLang}
                setOpenList = {setOpenListLang}
                onSelected={onSelectedLangHandler}
                defaultValue = {langSelected.value}
                listItem={listLangHandler} >
                <MdLanguage/>
            </BasicSelector>
            <div className='flex justify-center items-center'>
                <Link to={`${process.env.REACT_APP_WEB_APPS_BASE_URL}/${tenantUsername}/token/${_token}`}>
                    <img src={`/logo-white.webp`} title='Back to ABSAT applications' alt='Back to ABSAT applications' className='w-8 hover:w-9' />
                </Link>
            </div>
        </div>
    )
}

export default UserBar