import React from 'react'
import { MwSelector,MwInputText,TitleGroup } from '../ui'

const FormPrivateInfoCitizenship = ({
    privateInfo,
    setPrivateInfo,
    nationalityList,
    nationalitySelected,
    setNationalitySelected,
    genderList,
    genderSelected,
    setGenderSelected
}) => {
    return (
        <div>
                        <TitleGroup>المواطنة</TitleGroup>
                        <div>
                            <div className='flex gap-5'>
                                <div className='relative flex-1'>
                                    <MwSelector
                                        label={`الجنسية`}
                                        initalValue='أختار الجنسية...'
                                        dataType='labelValue'
                                        _data={nationalityList}
                                        selectedItem={nationalitySelected}
                                        setSelectedItem={setNationalitySelected}
                                        withAddNew = {false}
                                    />
                                </div>
                                <div className='relative flex-1'>
                                    <MwSelector
                                        label={`النوع`}
                                        initalValue='أختار النوع...'
                                        dataType='labelValue'
                                        _data={genderList}
                                        selectedItem={genderSelected}
                                        setSelectedItem={setGenderSelected}
                                        withAddNew = {false}
                                    />
                                </div>
                            </div>
                            
                            <div className='flex gap-5'>
                                <div className='flex-1'>
                                    <MwInputText
                                        label = "رقم الهوية"
                                        value = {privateInfo?.identificationId}
                                        onChange = { (e) => setPrivateInfo({...privateInfo,identificationId:e.target.value})}
                                        classNameInput = {`font-bold `}
                                    />
                                </div>
                                <div className='flex-1'>
                                    <MwInputText
                                        label = "رقم جواز السفر"
                                        value = {privateInfo?.passportId}
                                        onChange = { (e) => setPrivateInfo({...privateInfo,passportId:e.target.value})}
                                        classNameInput = {`font-bold `}
                                    />
                                </div>
                            </div>
                            <div className='flex gap-5'>
                                <div className='flex-1'>
                                    <MwInputText
                                        label = "تاريخ الميلاد"
                                        inputType="date"
                                        value = {privateInfo?.dob}
                                        onChange = { (e) => setPrivateInfo({...privateInfo,dob:e.target.value})}
                                        classNameInput = {`font-bold `}
                                    />
                                </div>
                                <div className='flex-1'>
                                    <MwInputText
                                        label = "مكان الميلاد"
                                        value = {privateInfo?.placeOfBirth}
                                        onChange = { (e) => setPrivateInfo({...privateInfo,placeOfBirth:e.target.value})}
                                        classNameInput = {`font-bold `}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
    )
}

export default FormPrivateInfoCitizenship