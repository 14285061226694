import React from 'react'
import { DataListViewRow } from '../ui/data-view'

const AttendanceLogListItem = ({item,currentLangId}) => {
    const name = currentLangId === 'en' ? item.emp_name_en || item.emp_name_ar  : item.emp_name_ar || item.emp_name_en 
    return (
        <DataListViewRow >
            <div className='w-20 text-center'>{item.emp_no}</div>
            <div className='flex-1'>{name || 'غير مسجل في النظام'}</div>
            <div className='w-20 text-center'> {item.type} </div>
            <div className='w-20 text-center'> {item.provider_type} </div>
            <div className='w-32 text-center '> {item.attendance_date} </div>
        </DataListViewRow>
    )
}

export default AttendanceLogListItem