import React from 'react'
import CardHeaderWithActions from '../ui/CardHeaderWithActions'
import { CardBasic, MwButton,MwInputText,MwSelector, MwSelectorMulti, MwTextArea,locationList } from '../ui'

const AddNewLocation = ({
    onHide,
    saveHandler,
    locationState,
    setLocationState,
    countrySelected,
    setCountrySelected,
    countryList,
    errors,
}) => {
    return (
        <div  className='p-3'>
            <CardHeaderWithActions title={`إدارة المواقع`}>
            { onHide && <MwButton type='cancelBtn' inGroup={true} onClick={()=>onHide()}>إغلاق</MwButton> }
                <MwButton type='saveBtn' inGroup={true} onClick={saveHandler}>حفظ</MwButton>
            </CardHeaderWithActions>
            <div className='text-sm font-bold text-slate-500 p-2'>
                <span> أضف موقع جديد </span>
            </div>
            <CardBasic>
                <div className='w-full flex flex-col gap-5'>
                    <div className='flex flex-col gap-3'>
                        <div className='flex-1'>
                            <MwInputText
                                label="اسم الموقع / المكان"
                                value = {locationState.name}
                                onChange = { (e) => setLocationState({ ...locationState,name:e.target.value}) }
                                classNameInput = {`font-bold `}
                                invalid={!!errors?.name}
                                invalidMsg={errors?.name}
                            />
                        </div>
                        <div className='flex gap-3'>
                            <div className='flex-1 relative'>
                                <MwSelector
                                    label={`الدولة`}
                                    dataType='labelValue'
                                    initalValue='أختار الدولة...'
                                    _data={countryList}
                                    selectedItem={countrySelected}
                                    setSelectedItem={setCountrySelected}
                                    withAddNew ={false}
                                    styleList={`${ !!errors?.country && ' border border-red-500 ' }`}
                                />
                                { !!errors?.country && <div className='px-1 text-red-700 '> {errors?.country} </div> }
                            </div>
                            <div className='flex-1'>
                                <MwInputText
                                    label="اسم المدينة "
                                    value = {locationState.address.city}
                                    onChange = { (e) => setLocationState({ ...locationState,address:{...locationState.address,city:e.target.value}}) }
                                    classNameInput = {`font-bold `}
                                    invalid={!!errors?.city}
                                    invalidMsg={errors?.city}
                                />
                            </div>
                        </div>
                        <div  className='flex gap-3'>
                            <div className='flex-1'>
                                <MwInputText
                                    label="اسم الحي "
                                    value = {locationState.address.region}
                                    onChange = { (e) => setLocationState({ ...locationState,address:{ ...locationState.address,region:e.target.value}}) }
                                    classNameInput = {`font-bold `}
                                    invalid={!!errors?.region}
                                    invalidMsg={errors?.region}
                                />
                            </div>
                            <div className='flex-1'>
                                <MwInputText
                                    label="اسم الشارع "
                                    value = {locationState.address.street}
                                    onChange = { (e) => setLocationState({ ...locationState,address:{ ...locationState.address,street:e.target.value}}) }
                                    classNameInput = {`font-bold `}
                                    invalid={!!errors?.street}
                                    invalidMsg={errors?.street}
                                />
                            </div>
                        </div>
                        <div  className='flex gap-3'>
                            <div className='flex-1'>
                                <MwInputText
                                    label="الرمز البريدي"
                                    value = {locationState.address.postCode}
                                    onChange = { (e) => setLocationState({ ...locationState,address:{ ...locationState.address,postCode:e.target.value}}) }
                                    classNameInput = {`font-bold `}
                                    invalid={!!errors?.postCode}
                                    invalidMsg={errors?.postCode}
                                />
                            </div>
                            <div className='flex-1 flex gap-1'>
                                <div className='flex-1'>
                                    <MwInputText
                                        label="احدِثيات خرائط جوجل"
                                        placeholder='Lat'
                                        value = {locationState.address.lat}
                                        onChange = { (e) => setLocationState({ ...locationState,address:{ ...locationState.address,lat:e.target.value}}) }
                                        classNameInput = {`font-bold `}
                                        invalid={!!errors?.lat}
                                        invalidMsg={errors?.lat}
                                    />
                                </div>
                                <div className='flex-1'>
                                    <MwInputText
                                        label="Google Map Location"
                                        placeholder="Lng"
                                        value = {locationState.address.lng}
                                        onChange = { (e) => setLocationState({ ...locationState,address:{ ...locationState.address,lng:e.target.value}}) }
                                        classNameInput = {`font-bold `}
                                        invalid={!!errors?.lng}
                                        invalidMsg={errors?.lng}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col '>
                        <div className='flex-1'>
                            <MwInputText
                                label="رقم اييي بي IP جهاز البصمة"
                                value = {locationState.ipFingerprint}
                                onChange = { (e) => setLocationState({ ...locationState,ipFingerprint:e.target.value}) }
                                classNameInput = {`font-bold `}
                                invalid={!!errors?.target}
                                invalidMsg={errors?.target}
                            />
                        </div>
                        <div className='flex-1 flex justify-start  items-center gap-2 pt-4 px-1'>
                            <input type='checkbox' value={locationState.haveFingerprint} checked={locationState?.haveFingerprint} onChange={(e) => setLocationState({ ...locationState,haveFingerprint: !locationState.haveFingerprint})} />
                            <label htmlFor="">هل يوجد جهاز بصمة بالموقع</label>
                        </div>
                    </div>
                    <div className='flex-1'>
                        <MwInputText
                            value = {locationState.description}
                            placeholder={`ادخل وصف الموقع/المكان`}
                            onChange = { (e) => setLocationState({ ...locationState,description:e.target.value}) }
                            classNameInput = {`font-bold `}
                            invalid={!!errors?.description}
                            invalidMsg={errors?.description}
                        />
                    </div>
                </div>
            </CardBasic>

        </div>
    )
}

export default AddNewLocation