import React, { useState ,useEffect } from 'react'
import CardHeaderWithActions from '../../components/ui/CardHeaderWithActions';
import { MwButton,MwSpinnerButton } from '../../components/ui';
import AddNewSkillTypes from '../../components/skill-types/AddNewSkillTypes';
import { useCurrent,useDelete,useFetch,usePost } from '../../hooks';
import SkillsTypesList from '../../components/skill-types/SkillsTypesList';

const SkillTypes = () => {

    const [skillTypeState,setSkillTypeState] = useState({});
    const [errors,setErrors] = useState();
    const {currentTenantId,currentCompanyId,currentBranchId,userId} = useCurrent()
    const {data:resultPost,loading,postData} = usePost();
    const {data:resultList,loading:loadingList,refreshHandler} = useFetch(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/skills-type/index/${currentTenantId}/${currentCompanyId}`);
    const {data:resultDelete,loading:loadingDeleting,deleteItem} = useDelete()

    const saveHandler = async() => {
        setErrors({})
        if(!skillTypeState.name){
            setErrors({name:"يجب ادخال نوع المهارة"})
            return;
        }
        const _data = {...skillTypeState,tenant_id:currentTenantId,company_id:currentCompanyId,branch_id:currentBranchId,created_by:userId}
        
        await postData(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/skills-type/all`,_data);
        console.log(_data)
        refreshHandler();
    }

    const deleteHandler = (id) =>{
        deleteItem(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/skills-type/${id}`)
    }

    useEffect(() => {
        if(resultDelete){
            refreshHandler();
        }
    },[resultDelete]);

    return (
        <>
            <CardHeaderWithActions title={` المهارات الوظيفية`}>
                {/* { !loadingPost && <MwButton type='cancelBtn' inGroup={true} onClick={()=>onHide()}>إغلاق</MwButton> } */}
                { loading ? <MwSpinnerButton/> : <MwButton type='saveBtn' inGroup={true} onClick={saveHandler}>حفظ</MwButton> }
            </CardHeaderWithActions>
            <AddNewSkillTypes errors={errors} skillTypeState={skillTypeState} setSkillTypeState={setSkillTypeState}/>
            {!loadingDeleting ? <SkillsTypesList data={resultList}  loading={loadingList} deleteHandler={deleteHandler} /> : <MwSpinnerButton/>}
        </>
    )
}

export default SkillTypes