import React from 'react'
import { MwInputText, TitleGroup } from '../ui'

const FormPrivateInfoEmergency = ({privateInfo,setPrivateInfo}) => {
    return (
        <div>
            <TitleGroup> التواصل في حالة الطواي</TitleGroup>
            <div className='flex gap-5'>
                <div className='flex-1'>
                    <MwInputText
                        label="الاسم"
                        value = {privateInfo?.emergencyPersonName}
                        onChange = { (e) => setPrivateInfo({...privateInfo,emergencyPersonName:e.target.value})}
                        classNameInput = {`font-bold `}
                    />
                </div>
                <div className='flex-1'>
                    <MwInputText
                        label=" الهاتف"
                        value = {privateInfo?.emergencyPersonPhone}
                        onChange = { (e) => setPrivateInfo({...privateInfo,emergencyPersonPhone:e.target.value})}
                        classNameInput = {`font-bold `}
                    />
                </div>
            </div>
        </div>
    )
}

export default FormPrivateInfoEmergency