import React,{ useState} from 'react'
import {FiSettings} from 'react-icons/fi'
import MeunSideSetting from '../components/settings/MeunSideSetting'
import { NoPermission } from '../components/ui'
import { useCurrent,  } from '../hooks'
import SkillTypes from './Configuration/SkillTypes'
import Locations from './Locations'
import WorkingHours from './WorkingHours'
import TimesZone from './TimesZone'

const Settings = () => {

    const [activeMenu,setActiveMenu] = useState('SkillsTypes')
    const [loading,setLoading] = useState(false);
    const {user} = useCurrent();

    return (
        <>
            <div className='flex justify-between items-center pb-5 pt-2'>
                <div className=' flex gap-1  text-sm text-gray-400 '>
                    <FiSettings size={18} /> 
                    إعدادات النظام 
                </div>
            </div>
            <div className='flex gap-2 mb-5'>
                <MeunSideSetting activeMenu = {activeMenu} setActiveMenu = {setActiveMenu}/>
                {
                    !loading &&
                    <div className='flex-1 bg-slate-50 rounded-lg p-3 overflow-auto h-[calc(100vh-200px)] '>
                        {   activeMenu === 'SkillsTypes'  && <SkillTypes />}
                        {   activeMenu === 'Locations'  && <Locations /> }
                        {   activeMenu === 'WorkingHours'  && <WorkingHours /> }
                        {   activeMenu === 'TimesZone'  && <TimesZone /> }
                        {
                            (
                                activeMenu !== 'SkillsTypes' &&  activeMenu !== 'Locations' &&  activeMenu !== 'WorkingHours'  &&  activeMenu !== 'TimesZone'
                            ) && <NoPermission msg={`ليس لديك صلاحية للدخول لهذة الصفحة`} />    
                        }
                    </div> 
                }
            </div>
        </>
    )

}

export default Settings