import React, { useEffect,useState } from 'react'
import CardHeaderWithActions from '../../components/ui/CardHeaderWithActions'
import { useCurrent, usePost } from '../../hooks'
import AttendanceLogList from '../../components/attendance/AttendanceLogList';
import AttendanceReportList from '../../components/attendance/AttendanceReportList';
import AttendanceFilter from '../../components/attendance/AttendanceFilter';
import MwTabView from '../../components/ui/tab/MwTabView';
import MwTabViewHead from '../../components/ui/tab/MwTabViewHead';
import MwTabViewBody from '../../components/ui/tab/MwTabViewBody';

const AttendanceManager = () => {
    const [tabActive,setTabActive] = useState('Report')
    const tabTitleList = [
        {
            label:'التقرير الشهري',
            value:'Report'
        },
        {
            label:'تفاصيل السجل',
            value:'Logs'
        }
    ]
    const [filterState,setFilterState] = useState({
        startDate:'',
        endDdate: '',
    })
    const [typeSelected,setTypeSelected] = useState();
    const [empSelected,setEmpSelected] = useState();
    const {currentTenantId,currentCompanyId,currentBranchId,currentLangId} = useCurrent();
    const {data:logAttendanceResult,loading:loadingAttendance,postData:getAttendanceLogs} = usePost();
    const {data:reportAttendanceResult,loading:loadingAttendanceReport,postData:getAttendanceReport} = usePost();

    const filterHandler = () => {
        let _data = {
            tenant_id: currentTenantId,
            company_id: currentCompanyId,
            emp_no: empSelected?.value || null,
            start_date: filterState?.startDate || null,
            end_date: filterState?.endDdate || null,
            type: typeSelected?.value || null
        }
        if(tabActive === 'Report'){
            getAttendanceReport(`${process.env.REACT_APP_HR_ATTENDANCE_API_BASE_URL}/get-monthy-report-by-tenant`,_data)
        }else{
            getAttendanceLogs(`${process.env.REACT_APP_HR_ATTENDANCE_API_BASE_URL}/get-by-tenant`,_data)
        }
    }
    useEffect(()=>{
        let _data = {
            tenant_id: currentTenantId,
            company_id: currentCompanyId,
            branch_id: null,
        }
        if(tabActive === 'Report'){
            getAttendanceReport(`${process.env.REACT_APP_HR_ATTENDANCE_API_BASE_URL}/get-monthy-report-by-tenant`,_data)
        }else{

            getAttendanceLogs(`${process.env.REACT_APP_HR_ATTENDANCE_API_BASE_URL}/get-by-tenant`,_data)
        }
    },[]);
    
    useEffect(() => {
        if(logAttendanceResult){
            console.log(logAttendanceResult)
        }
    },[logAttendanceResult])

    return (
        <div>
            <CardHeaderWithActions title={`الحضور والانصراف`}></CardHeaderWithActions>
            <MwTabView>
                <MwTabViewHead tabTitleList={tabTitleList} setTabActive={setTabActive} tabActive={tabActive}  />
                <MwTabViewBody>
                    <div className='flex gap-3 justify-between '>
                        <div className=' flex-1'>
                            { tabActive === 'Logs' && <AttendanceLogList currentLangId={currentLangId} data={logAttendanceResult} loading={loadingAttendance}/> }
                            { tabActive === 'Report' && <AttendanceReportList currentLangId={currentLangId} data={reportAttendanceResult} loading={loadingAttendanceReport}/> }
                        </div>
                        <AttendanceFilter 
                            filterState = {filterState} 
                            setFilterState={setFilterState}
                            filterHandler = {filterHandler}
                            typeSelected = {typeSelected}
                            setTypeSelected = {setTypeSelected}
                            empSelected = {empSelected}
                            setEmpSelected = {setEmpSelected}
                            />
                    </div>
                </MwTabViewBody>
            </MwTabView>

            
        </div>
    )
}

export default AttendanceManager