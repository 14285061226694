import React,{useState,useEffect} from 'react'
import FormEmployee from '../../components/employee/FormEmployee'
import CardHeaderWithActions from '../../components/ui/CardHeaderWithActions'
import { MwButton, MwSpinner, MwSpinnerButton } from '../../components/ui'
import { useCurrent, usePost,useFetch, usePut } from '../../hooks';
import AlertMsg from '../../components/ui/AlertMsg';
import { useParams } from 'react-router-dom';
import {  useTranslation } from 'react-i18next';

const EditEmployee = () => {
    const [t] = useTranslation('global')

    const empId = useParams().id;
    const [saved,setSaved] = useState(false);
    const [error,setError] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedPermit, setSelectedPermit] = useState(null);
    const [employee,setEmployee] = useState({
        empNo: '',
        nameEn:'',
        nameAr:'',
        workMobile:'',
        workPhone:'',
        workEmail:'',
    });
    const [errors,setErrors] = useState({
        nameEn:'',
    });
    const allowImgTypes = ["image/png", "image/jpg", "image/jpeg", "image/gif"];
    const allowFileTypes = ["application/pdf", "application/msword", "application/docx"];
    const [empTagList,setEmpTagList] = useState([]);
    const [jobPositionSelected,setJobPositionSelected] = useState([]);
    const [jobPositionListData,setJobPositionListData] = useState([]);
    const [managerSelected, setManagerSelected] = useState({});
    const [departmentSelected, setDepartmentSelected] = useState({});
    const [coachSelected, setCoachSelected] = useState({});
    const [employeesList, setEmployeesList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [coachList, setCoachList] = useState([]);

    // start employee private info state
    const [privateInfo,setPrivateInfo] = useState({});
    const [nationalityList,setNationalityList] = useState([]);
    const [nationalitySelected,setNationalitySelected] = useState({});
    const [genderList,setGenderList] = useState([
        {
            label:'Male',
            value: 'male',
            lang: 'en'
        },{
            label:'Female',
            value: 'female',
            lang: 'en'
        },{
            label:'ذكر',
            value: 'male',
            lang: 'ar'
        }
        ,{
            label:'انثي',
            value: 'female',
            lang: 'ar'
        }
    ]);
    const [genderSelected,setGenderSelected] = useState({});
    const [maritalStatusList,setMaritalStatusList] = useState([
        {
            label:'Single',
            value: 'single',
            lang: 'en'
        },
        {
            label:'Maried',
            value: 'maried',
            lang: 'en'
        },
        {
            label:'اعزب',
            value: 'single',
            lang: 'ar'
        },
        {
            label:'متزوج',
            value: 'maried',
            lang: 'ar'
        }
    ]);
    const [maritalStatusSelected,setMaritalStatusSelected] = useState({});
    const [certificateLevelList,setCertificateLevelList] = useState([
        {
            label:'High School',
            value: 'highSchool',
            lang: 'en'
        },
        {
            label:'Diploma',
            value: 'diploma',
            lang: 'en'
        },
        {
            label: "Bachelor's",
            value: 'bachelor',
            lang: 'en'
        },
        {
            label: "Master's",
            value: 'master',
            lang: 'en'
        },
        {
            label:'Ph.D',
            value: 'Ph.D',
            lang: 'en'
        },
        {
            label:'ثانوية',
            value: 'highSchool',
            lang: 'ar'
        },
        {
            label:'دبلومه',
            value: 'diploma',
            lang: 'ar'
        },
        {
            label: "بكالوريس",
            value: 'bachelor',
            lang: 'ar'
        },
        {
            label: "ماجستر",
            value: 'master',
            lang: 'ar'
        },
        {
            label:'دكتورا',
            value: 'Ph.D',
            lang: 'ar'
        },
    ]);
    const [certificateLevelSelected,setCertiificationLevelSelected] = useState({});
    const [bankAccountList,setBankAccountList] = useState([]);
    const [bankAccountSelected,setBankAccountSelected] = useState({});
    const [languagesList,setLanguagesList] = useState([
        {
            label: 'Arabic',
            value: 'arabic',
            lang:'en'
        },
        {
            label: 'English',
            value: 'english',
            lang:'en'
        },
        {
            label: 'French',
            value: 'french',
            lang:'en'
        },
        {
            label: 'Other',
            value: 'other',
            lang:'en'
        },
        {
            label: 'العربية',
            value: 'arabic',
            lang:'ar'
        },
        {
            label: 'الانجليزية',
            value: 'english',
            lang:'ar'
        },
        {
            label: 'الفرنسية',
            value: 'french',
            lang:'ar'
        },
        {
            label: 'اخري',
            value: 'other',
            lang:'ar'
        }
    ]);
    const [languagesListSelected,setLanguagesListSelected] = useState({});
    // end employee private info state

    // start work information
    const [showLocationModal,setShowLocationModal] = useState(false);
    const [showWorkingHoursModal,setShowWorkingHoursModal] = useState(false);
    const [showTimezoneModal,setShowTimezoneModal] = useState(false);
    const [locationList,setLocationList] = useState([]);
    const [locationSelected,setLocationSelected] = useState({});
    const [approversExpenseSelected,setApprovalsExpenseSelected] = useState({});
    const [approversTimeoffSelected,setApprovalsTimeoffSelected] = useState({});
    const [workingHoursList,setWorkingHoursList] = useState([]);
    const [workingHoursSelected,setWorkingHoursSelected] = useState({});
    const [timezoneList,setTimezoneList] = useState([]);
    const [timezoneSelected,setTimezoneSelected] = useState({});
    // Start Resume
    const [showResumeLines,setShowResumeLines] = useState(false);
    const [resumeLinesList,setResumeLinesList] = useState([]);
    const [showSkills,setShowSkills] = useState(false);
    const [skillsList,setSkillsList] = useState([]);
    // Start employee configuration
    const [typeEmployeeSelected,setTypeEmployeeSelected ] = useState();
    const [relatedUserSelected,setRelatedUserSelected] = useState();
    const [employeeConfigState,setEmployeeConfigState] = useState({
        pincode:'',
        badgeId:'',
        isManager: false,
        isTrainer: false,
        isApprovalExpense: false,
        isApprovalTimeoff: false,
    });
    const [contractSelected,setContractSelected] = useState();

    const {currentTenantId,currentCompanyId,currentBranchId,userId,currentLangId} = useCurrent();
    const {data:resultEmployeesData,loading:loadingEmployees,postData:postDataEmployees} = usePost();
    const {data:resultDepartmentsData,loading:loadingDepartments,postData:postDataDepartments} = usePost();
    const {data:jobPositionData,loading:loadingJobPosition,postData:getJobPosition} = usePost();
    const {data:resultPut,loading:loadingPut,putData} = usePut();

    const {data:NationalityListData,loading:NationalityListLoading} = useFetch(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/countries`);
    const {data:resultLocationList,loading:loadingLocationList,postData:getLocations} = usePost();
    const {data:resultWorkingHours,loading:loadingWorkingHours,refreshHandler:getWorkingHours} = useFetch(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/define-settings/${currentTenantId}/${currentCompanyId}/working-hours`);
    const {data:resultTimeszone,loading:loadingTimeszone,refreshHandler:getTimeszone} = useFetch(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/define-settings/${currentTenantId}/${currentCompanyId}/times-zone`);
    const {data:resultEmployee,loading:loadingEmployee} = useFetch(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/employees/${currentTenantId}/${currentCompanyId}/${currentBranchId}/${empId}`);
    const {data:resultUploads,loading:loadingUploadPic,postData:uploadPic} = usePost(false);
    const {data:resultUploadPermit,loading:loadingPermitFile,postData:uploadPermit} = usePost(false);
    const {data:resultusers,loading:loadingUsers} = useFetch(`${process.env.REACT_APP_Auth_API_BASE_URL}/users/tenant/${currentTenantId}/${currentCompanyId}`);

    const _data = {
        tenant_id:currentTenantId,
        company_id:currentCompanyId,
        branch_id:currentBranchId
    }

    const handlePermitFileUpload = async(event) =>{
        const _file = event.target.files[0] || null;
        if(_file){
            console.log(_file);
            if(allowFileTypes.find(type => type ===  _file.type)){
                const fileName = _file.name;
                try {
                    const _formData = new FormData();
                    _formData.append('myfile',_file,fileName);
                    uploadPermit(`${process.env.REACT_APP_UPLOADS_API_BASE_URL}/hr-employees-visa-permits`,_formData)
                } catch (error) {
                    console.error('Error uploading image:', error);
                }
            }
        }
    }

    const handleImageUpload = async(event) => {
        const file = event.target.files[0] || null;
        console.log(file);
        if(file){
            if(allowImgTypes.find(type => type ===  file.type)){
                setSelectedImage(URL.createObjectURL(file));
                const fileName = file.name;
                try {
                    console.log('file => ',file);
                    const _formData = new FormData();
                    _formData.append('myfile',file,fileName);
                    uploadPic(`${process.env.REACT_APP_UPLOADS_API_BASE_URL}/hr-employees-pic`,_formData)
                } catch (error) {
                    console.error('Error uploading image:', error);
                }

            }else{
                console.log('error image type:', file.type);
            }
        }
    };

    const init = async() => {
        if(currentTenantId && currentCompanyId && currentBranchId){
            if(!loadingEmployees && !resultEmployeesData){
                postDataEmployees(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/employees`,_data);
            }
            if(!loadingDepartments && !resultDepartmentsData){
                getDepartments();
            }
            if(!jobPositionData){
                getAllJobPosition();
            }
            getAllLocations();
        }

    }
    
    const getAllJobPosition = async() => {
        await getJobPosition(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/job-positions`,_data);
    }
    
    const getDepartments = async () =>{
        await postDataDepartments(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/departments`,_data);
    }

    const getAllLocations = async() => {
        await getLocations(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/locations`,_data);
    }

    const getWorkingHoursList = () => {
        getWorkingHours();
    }

    const getTimeszoneList = () =>{
        getTimeszone();
    }

    const getResumeLines = () =>{
        
    }

    const getSkills = () =>{
        
    }
    
    useEffect(() =>{
        init();
    },[]);

    useEffect(() =>{
        if(resultEmployee && resultusers){
            console.log('resultEmployee',resultEmployee)
            setEmployee({
                empNo: resultEmployee?.data?.emp_no,
                nameEn: resultEmployee?.data?.name_en,
                nameAr: resultEmployee?.data.name_ar,
                workMobile: resultEmployee?.data?.work_mobile,
                workPhone: resultEmployee?.data?.work_phone,
                workEmail: resultEmployee?.data?.work_email,
            });
            setDepartmentSelected({
                label: resultEmployee?.data?.department_relation?.name,
                value: resultEmployee?.data?.department_relation?.id,
                lang:currentLangId
            })
            setJobPositionSelected({
                label: resultEmployee?.data?.job_position?.name,
                value: resultEmployee?.data?.job_position?.id,
                lang:currentLangId
            })
            setManagerSelected({
                label: resultEmployee?.data?.manager?.name_ar || resultEmployee.data?.manager?.name_en,
                value: resultEmployee?.data?.manager?.id,
                lang:currentLangId
            })
            setCoachSelected({
                label: resultEmployee?.data?.coach?.name_ar || resultEmployee.data?.coach?.name_en,
                value: resultEmployee?.data?.coach?.id,
                lang:currentLangId
            })
            setSelectedImage(resultEmployee?.data?.pic)
            setEmpTagList(JSON.parse(resultEmployee?.data?.tags))

            setResumeLinesList(resultEmployee.data?.resume_lines);

            if( resultEmployee.data?.resume_skills?.length > 0 ){
                const _listSkills = resultEmployee.data?.resume_skills.map(l => ({
                    skill_type_id :{
                        label: l.type?.name,
                        value: l.type?.id,
                    } ,
                    skill_id: {
                        label: l.skills?.title,
                        value: l.skills?.id,
                    },
                    skill_level_id:{
                        label: l.levels?.title,
                        value: l.levels?.id,
                        progress: l.levels?.level_percent
                    },
                })) 
                setSkillsList([..._listSkills]);
            }
            setLocationSelected({
                label: resultEmployee.data?.work_location?.name,
                value: resultEmployee.data?.work_location?.id,
                lang: currentLangId
            });
            setApprovalsExpenseSelected({
                label: resultEmployee.data?.approvers_expense?.name_ar || resultEmployee.data?.approvers_expense?.name_en,
                value: resultEmployee.data?.approvers_expense?.id,
                lang: currentLangId
            });
            setApprovalsTimeoffSelected({
                label: resultEmployee.data?.approvers_timeoff?.name_ar || resultEmployee.data?.approvers_timeoff?.name_en,
                value: resultEmployee.data?.approvers_timeoff?.id,
                lang: currentLangId
            });
            setWorkingHoursSelected({
                label: resultEmployee.data?.working_hours?.label ,
                value: resultEmployee.data?.working_hours?.id,
                lang: currentLangId
            });
            setTimezoneSelected({
                label: resultEmployee.data?.time_zone?.label,
                value: resultEmployee.data?.time_zone?.id,
                lang: currentLangId
            });
            setNationalitySelected({
                label: currentLangId==='ar' ? resultEmployee.data?.private_info?.nationality?.nationality_name_ar : resultEmployee.data?.private_info?.nationality?.nationality_name_en,
                value: resultEmployee.data?.private_info?.nationality?.id,
                lang: currentLangId
            })
            
            setGenderSelected({
                label: t(`hr.${resultEmployee.data?.private_info?.gender}`),
                value: resultEmployee.data?.private_info?.gender,
                lang: currentLangId
            })
            setMaritalStatusSelected({
                label: t(`hr.${resultEmployee.data?.private_info?.marital_status}`),
                value: resultEmployee.data?.private_info?.marital_status,
                lang: currentLangId
            })
            setLanguagesListSelected({
                label: t(`hr.${resultEmployee.data?.private_info?.main_language}`),
                value: resultEmployee.data?.private_info?.main_language,
                lang: currentLangId
            })
            setCertiificationLevelSelected({
                label: t(`hr.${resultEmployee.data?.private_info?.certificate_level_id?.trim()}`),
                value: resultEmployee.data?.private_info?.certificate_level_id?.trim(),
                lang: currentLangId
            })
            setPrivateInfo({
                email:  resultEmployee.data?.private_info?.email,
                phone: resultEmployee.data?.private_info?.phone,
                emergencyPersonName: resultEmployee.data?.private_info?.emergency_person_name,
                emergencyPersonPhone: resultEmployee.data?.private_info?.emergency_person_phone,
                identificationId: resultEmployee.data?.private_info?.identification_id,
                passportId: resultEmployee.data?.private_info?.passport_id,
                dob: resultEmployee.data?.private_info?.dob,
                placeOfBirth: resultEmployee.data?.private_info?.place_of_birth,
                countryOfBirth: resultEmployee.data?.private_info?.country_of_birth,
                homeAddress: resultEmployee.data?.private_info?.home_address_id,
                homeWorkDistance: resultEmployee.data?.private_info?.home_work_distance,
                numberOfChildren: resultEmployee.data?.private_info?.number_of_children,
                fieldOfStudy: resultEmployee.data?.private_info?.field_of_study,
                schoolName: resultEmployee.data?.private_info?.school_name,
                visaNo: resultEmployee.data?.work_permit?.visa_no,
                visaPermitNo:  resultEmployee.data?.work_permit?.visa_permit_no,
                visaExpireDate:  resultEmployee.data?.work_permit?.visa_expire_date,
                workPermitExpireDate:  resultEmployee.data?.work_permit?.work_permit_expire_date
            })
            setSelectedPermit(resultEmployee.data?.work_permit?.work_permit_file)
            setEmployeeConfigState({
                pincode:resultEmployee.data?.config?.pincode,
                badgeId: resultEmployee.data?.config?.badge_id,
                isManager: resultEmployee.data?.config?.is_manager,
                isTrainer: resultEmployee.data?.config?.is_trainer,
                isApprovalExpense: resultEmployee.data?.config?.is_approval_expense,
                isApprovalTimeoff: resultEmployee.data?.config?.is_approval_timeoff,
            });
            setTypeEmployeeSelected({
                label: t(`hr.${resultEmployee.data?.config?.emp_type?.trim()}`),
                value: resultEmployee.data?.config?.emp_type,
                lang: currentLangId
            })
            const _relatedUser =  resultusers.data.map(user =>{
                if(user._id === resultEmployee.data?.config?.emp_related_user_id?.trim() ){
                    return {
                            label : user.name,
                            value: user._id,
                            lang: currentLangId
                        }
                }
            })[0];
            setRelatedUserSelected(_relatedUser)
        }
    },[resultEmployee,resultusers]);

    useEffect(() =>{
        if(resultUploads){
            resultUploads.data.location && setSelectedImage(resultUploads.data.location)
        }
    },[resultUploads]);

    useEffect(() =>{
        if(resultUploadPermit){
            resultUploadPermit.data.location && setSelectedPermit(resultUploadPermit.data.location)
        }
    },[resultUploadPermit]);

    useEffect(() =>{
        if(resultEmployeesData){
            const empList = resultEmployeesData.data.map(employee => ({
                label:employee[`name_${currentLangId}`],
                value:employee.id,
                lang: currentLangId
                
            }));
            setEmployeesList(empList);
            setCoachList(empList);
            //console.log(employeesList);
        }
    },[resultEmployeesData]);

    useEffect(() =>{
        if(jobPositionData){
            const _list = jobPositionData.data.map(job => ({
                label : job.name,
                value : job.id,
                lang: currentLangId
            }))
            setJobPositionListData(_list)
            console.log('_list',_list);
        }
    },[jobPositionData]);

    useEffect(() => {
        if(NationalityListData){
            const _list= NationalityListData?.data.map(nat => ({
                label: nat.nationality_name_ar,
                value:nat.id,
                lang: 'ar'
            }));
            setNationalityList(_list);
        }
    },[NationalityListData])

    
    useEffect(() =>{
        if(resultDepartmentsData){
            const deptList = resultDepartmentsData.data.map(dept => ({
                label:`${dept.parent_relation?.parent_relation?.parent_relation?.name ? dept.parent_relation?.parent_relation?.parent_relation?.name+'/':'' } ${dept.parent_relation?.parent_relation?.name ? dept.parent_relation?.parent_relation?.name+'/' : ''} ${dept.parent_relation?.name || ''}/ ${dept.name}`,
                value:dept.id,
                lang: currentLangId
                
            }));
            setDepartmentList(deptList);
            console.log(employeesList);
        }
    },[resultDepartmentsData]);

    useEffect(()=>{
        if(resultPut){
            if(resultPut.status === 1){
              //  restForm();
                setSaved(true);
                setTimeout(() => {
                    setSaved(false);
                }, 1500);
            }else{
                setError(resultPut.message)
            }
        }
    },[resultPut]);

    useEffect(() => {
        if(resultLocationList){
            console.log('resultLocationList =>',resultLocationList);
            const _list = resultLocationList.data.map(location =>({
                label: location.name,
                value: location.id ,
                lang: currentLangId
            }));
            setLocationList(_list);
        }
    },[resultLocationList]);

    useEffect(() => {
        if(resultWorkingHours){
            const _list = resultWorkingHours.data.map(wh =>({
                label: wh.label,
                value: wh.id ,
                lang:  wh.lang.trim()
            }));
            setWorkingHoursList(_list)
            console.log('resultWorkingHours =>',workingHoursList);
        }
    },[resultWorkingHours]);

    useEffect(() => {
        if(resultTimeszone){
            const _list = resultTimeszone.data.map(tz =>({
                label: tz.label,
                value: tz.id ,
                lang:  tz.lang.trim()
            }));
            setTimezoneList(_list)
            console.log('resultTimeszone =>',workingHoursList);
        }
    },[resultTimeszone]);

    const restForm = () => {
        setEmployee({
            empNo: '',
            nameEn:'',
            nameAr:'',
            workMobile:'',
            workPhone:'',
            workEmail:'',
        });
        setPrivateInfo({
            email: '',
            phone: '',
            emergencyPersonName: '',
            emergencyPersonPhone:'',
            identificationId: '',
            passportId:'',
            dob: '',
            placeOfBirth: '',
            countryOfBirth:'',
            homeAddress: '',
            homeWorkDistance:'',
            numberOfChildren:'',
            fieldOfStudy: '',
            schoolName:'',
            visaNo:'',
            visaPermitNo:'',
            visaExpireDate:'',
            workPermitExpireDate:''
        })
        setSkillsList([]);
        setResumeLinesList([]);
        setEmployeeConfigState({
            pincode:'',
            badgeId:'',
            isManager: false,
            isTrainer: false,
            isApprovalExpense: false,
            isApprovalTimeoff: false,
        });
    }

    const submitHandler = () =>{
        setError(null);
        let _skillsList = [];
        let _resumeLinesList= [];
        if(skillsList.length > 0){
            skillsList.map((sk) => {
                _skillsList.push({
                        skill_type_id: sk.skill_type_id.value,
                        skill_id: sk.skill_id.value,
                        skill_level_id: sk.skill_level_id.value
                })
            });
        }
        if(resumeLinesList.length > 0){
            resumeLinesList.map(line => {
                _resumeLinesList.push({
                    title: line.title,
                    type: line.type,
                    date_start: line.dateStart || line.date_start || null,
                    date_end: line.dateEnd || line.date_end || null,
                    description: line.description
                })
            })
        }
        setErrors({});

        if(!employee.nameEn){
            setErrors({...errors, nameEn:'يجب ادخال الاسم' });
            return
        }
        const _data = {
            emp_no: employee.empNo,
            name_en: employee.nameEn,
            name_ar: employee.nameAr,
            job_position_id: jobPositionSelected.value,
            employee_type_id: '',
            tags: JSON.stringify(empTagList),
            department_id: departmentSelected?.value,
            manager_id: managerSelected?.value,
            coach_id: coachSelected?.value,
            pic:selectedImage,
            work_mobile: employee.workMobile,
            work_phone: employee.workPhone,
            work_email: employee.workEmail,
            work_location_id: locationSelected?.value || null,
            approvers_expense:approversExpenseSelected?.value || null,
            approvers_timeoff: approversTimeoffSelected?.value || null,
            working_hours_id: workingHoursSelected?.value || null,
            timezone : timezoneSelected?.value || null,
            related_user_id: relatedUserSelected?.value || null,
            current_contract_id: null,
            pin_code : employeeConfigState.pincode,
            badge_id : employeeConfigState.badgeId,
            tenant_id:currentTenantId,
            company_id:currentCompanyId,
            branch_id:currentBranchId,
            created_by:userId,
            private_info:{
                email : privateInfo.email || null,
                phone : privateInfo.phone || null,
                emergency_person_name: privateInfo.emergencyPersonName || null,
                emergency_person_phone: privateInfo.emergencyPersonPhone || null,
                gender: genderSelected.value || null,
                nationality_id: nationalitySelected.value || null,
                identification_id : privateInfo.identificationId || null,
                passport_id : privateInfo.passportId || null,
                dob: privateInfo.dob || null,
                place_of_birth: privateInfo.placeOfBirth || null,
                country_of_birth: privateInfo.countryOfBirth || null,
                home_address_id: privateInfo.homeAddress || null,
                home_work_distance: privateInfo.homeWorkDistance || null,
                marital_status: maritalStatusSelected.value || null,
                number_of_children: privateInfo.numberOfChildren || null,
                bank_account_id: bankAccountSelected.value || null,
                main_language: languagesListSelected.value || null,
                certificate_level_id : certificateLevelSelected.value || null,
                field_of_study: privateInfo.fieldOfStudy || null,
                school_name : privateInfo.schoolName || null,
            },
            resume_lines:_resumeLinesList,
            resume_skills:_skillsList,
            work_permits:{
                visa_no: privateInfo.visaNo || null,
                visa_permit_no: privateInfo.visaPermitNo || null,
                visa_expire_date: privateInfo.visaExpireDate || null,
                work_permit_expire_date: privateInfo.workPermitExpireDate || null,
                work_permit_file:selectedPermit || null,
            },
            employee_config:{
                emp_type:  typeEmployeeSelected?.value || null,
                emp_related_user_id:relatedUserSelected?.value || null,
                current_contract_id: contractSelected?.value || null,
                pincode: employeeConfigState.pincode || null,
                badge_id: employeeConfigState.badgeId || null,
                is_manager:  employeeConfigState.isManager || null,
                is_trainer: employeeConfigState.isTrainer || null,
                is_approval_expense: employeeConfigState.isApprovalExpense || null,
                is_approval_timeoff: employeeConfigState.isApprovalTimeoff || null,
            }

        };
        console.log('_data',_data);
        putData(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/employees/update/${empId}`,_data);

    }

    return (
        <>
            { loadingEmployee ? 
                <MwSpinner/> : 
                <div>
                    <CardHeaderWithActions title={`أضف موظف جديد`}>
                        <div className='flex '>
                            { !loadingPut && <MwButton type='cancelBtn' inGroup={true}>الغاء</MwButton>}
                            { loadingPut ? <MwSpinnerButton/> : <MwButton actionType='button' disabled={saved} type='saveBtn'  onClick={submitHandler} inGroup={true}>  حفظ </MwButton> }
                        </div>
                    </CardHeaderWithActions>
                    {saved && <AlertMsg>تم الحفظ بنجاح</AlertMsg> }
                    {error && <AlertMsg type={`Error`}>{error}</AlertMsg> }
                    <FormEmployee 
                        errors={errors}
                        employee={employee} 
                        loadingUploadPic = {loadingUploadPic}
                        handleImageUpload = {handleImageUpload}
                        selectedImage = {selectedImage}
                        setEmployee={setEmployee}
                        empTagList = {empTagList}
                        setEmpTagList = {setEmpTagList}
                        departmentList = {departmentList}
                        departmentSelected = {departmentSelected}
                        setDepartmentSelected = {setDepartmentSelected}
                        employeesList = {employeesList}
                        managerSelected = {managerSelected}
                        setManagerSelected = {setManagerSelected}
                        coachList = {coachList}
                        coachSelected = {coachSelected}
                        setCoachSelected = {setCoachSelected}
                        jobPositionListData = {jobPositionListData}
                        jobPositionSelected = {jobPositionSelected}
                        setJobPositionSelected = {setJobPositionSelected}
                        getAllJobPosition = {getAllJobPosition}
                        getDepartments = {getDepartments}
                        // private information
                        privateInfo = {privateInfo}
                        setPrivateInfo = {setPrivateInfo}
                        nationalityList = {nationalityList}
                        nationalitySelected = {nationalitySelected}
                        setNationalitySelected = {setNationalitySelected}
                        genderList = {genderList}
                        genderSelected  = {genderSelected}
                        setGenderSelected   = {setGenderSelected}
                        maritalStatusList   = {maritalStatusList}
                        maritalStatusSelected   = {maritalStatusSelected}
                        setMaritalStatusSelected = {setMaritalStatusSelected}
                        certificateLevelList = {certificateLevelList}
                        certificateLevelSelected = {certificateLevelSelected} 
                        setCertiificationLevelSelected = {setCertiificationLevelSelected}
                        bankAccountList = {bankAccountList}
                        bankAccountSelected = {bankAccountSelected} 
                        setBankAccountSelected = {setBankAccountSelected} 
                        languagesList = {languagesList}
                        languagesListSelected = {languagesListSelected}
                        setLanguagesListSelected = {setLanguagesListSelected}
                        // work information
                        locationList = {locationList}
                        locationSelected = {locationSelected}
                        setLocationSelected = {setLocationSelected}
                        getAllLocations = {getAllLocations}
                        showLocationModal = {showLocationModal}
                        setShowLocationModal = {setShowLocationModal}
                        approversExpenseSelected = {approversExpenseSelected}
                        setApprovalsExpenseSelected = {setApprovalsExpenseSelected}
                        approversTimeoffSelected = {approversTimeoffSelected}
                        setApprovalsTimeoffSelected = {setApprovalsTimeoffSelected}
                        workingHoursList = {workingHoursList}
                        workingHoursSelected = {workingHoursSelected}
                        setWorkingHoursSelected = {setWorkingHoursSelected}
                        timezoneList = {timezoneList}
                        timezoneSelected = {timezoneSelected}
                        setTimezoneSelected = {setTimezoneSelected}
                        showWorkingHoursModal = {showWorkingHoursModal}
                        setShowWorkingHoursModal = {setShowWorkingHoursModal}
                        getWorkingHoursList = {getWorkingHoursList}
                        showTimezoneModal = {showTimezoneModal}
                        setShowTimezoneModal = {setShowTimezoneModal}
                        getTimeszoneList = {getTimeszoneList}
                        // resume
                        showResumeLines = {showResumeLines}
                        setShowResumeLines = {setShowResumeLines}
                        getResumeLines = {getResumeLines}
                        resumeLinesList = {resumeLinesList}
                        setResumeLinesList = {setResumeLinesList}
                        getSkills = {getSkills}
                        showSkills = {showSkills}
                        setShowSkills = {setShowSkills}
                        skillsList = {skillsList}
                        setSkillsList = {setSkillsList}
                        handlePermitFileUpload = {handlePermitFileUpload}
                        loadingPermitFile = {loadingPermitFile}
                        selectedPermit={selectedPermit}
                        typeEmployeeSelected = {typeEmployeeSelected}
                        setTypeEmployeeSelected = {setTypeEmployeeSelected}
                        relatedUserSelected = {relatedUserSelected}
                        setRelatedUserSelected = {setRelatedUserSelected}
                        employeeConfigState = {employeeConfigState}
                        setEmployeeConfigState = {setEmployeeConfigState}
                        contractSelected = {contractSelected}
                        setContractSelected = {setContractSelected}
                        />
                </div>
            }
        </>

    )
}

export default EditEmployee