import React from 'react'
import { RiDeleteBin5Line } from "react-icons/ri";

const SkillsListItem = ({item,index,deleteHandler}) => {
    return (
        <div className='border-b p-2 text-sm flex flex-between items-center'>
            <div className='w-16 text-center'>{index+1}</div>
            <div className='flex-1'>{item?.type?.label || item?.skill_type_id?.label }</div>
            <div className='flex-1'>{item?.skills?.label || item?.skill_id?.label }</div>
            <div className='flex-1 text-xs'>
                <div className='text-center '>{item?.levels?.label || item?.skill_level_id?.label  }</div>
                <div className='flex-1 bg-slate-300 rounded-lg'>
                { (item?.levels?.progress || item?.skill_level_id?.progress) && <div className={`bg-slate-400 ${(item?.levels?.progress || item?.skill_level_id?.progress ) > 98 ? 'rounded-lg' : 'rounded-r-lg'}  text-center text-slate-50`} style={{ width: (item?.levels?.progress || item?.skill_level_id?.progress)+"%" }}>{item?.levels?.progress || item?.skill_level_id?.progress}%</div> }
                </div>
            </div>
            <div className='w-8 flex items-center justify-center pt-3'>
                <RiDeleteBin5Line size={16} onClick={()=>deleteHandler(index)}/>
            </div>


        </div>
    )
}

export default SkillsListItem