import React from 'react'
import { MwSelector,MwInputText,TitleGroup } from '../ui'
import FormPrivateInfoCitizenship from './FormPrivateInfoCitizenship'
import FormPrivateInfoMaritalStatus from './FormPrivateInfoMaritalStatus'
import FormPrivateInfoEducation from './FormPrivateInfoEducation'
import FormPrivateInfoContact from './FormPrivateInfoContact'
import FormPrivateInfoEmergency from './FormPrivateInfoEmergency'
import FormPrivateInfoWorkPermit from './FormPrivateInfoWorkPermit'

const FormPrivateInfo = ({
    privateInfo,
    setPrivateInfo,
    nationalityList,
    nationalitySelected,
    setNationalitySelected,
    genderList,
    genderSelected,
    setGenderSelected,
    maritalStatusList,
    maritalStatusSelected,
    setMaritalStatusSelected,
    certificateLevelList,
    certificateLevelSelected,
    setCertiificationLevelSelected,
    bankAccountList,
    bankAccountSelected,
    setBankAccountSelected,
    languagesList,
    languagesListSelected,
    setLanguagesListSelected,
    handlePermitFileUpload,
    loadingPermitFile,
    selectedPermit
}) => {
    return (
        <div>
            <div className='flex gap-5'>
                <div className='flex-1'>
                    <FormPrivateInfoCitizenship
                        privateInfo = {privateInfo}
                        setPrivateInfo = {setPrivateInfo}
                        nationalityList = {nationalityList}
                        nationalitySelected = {nationalitySelected}
                        setNationalitySelected = {setNationalitySelected}
                        genderList = {genderList}
                        genderSelected = {genderSelected}
                        setGenderSelected = {setGenderSelected}
                        />
                    <FormPrivateInfoMaritalStatus
                        privateInfo = {privateInfo}
                        setPrivateInfo = {setPrivateInfo}
                        maritalStatusList = {maritalStatusList}
                        maritalStatusSelected = {maritalStatusSelected}
                        setMaritalStatusSelected = {setMaritalStatusSelected}
                        />
                    <FormPrivateInfoEducation
                        privateInfo = {privateInfo}
                        setPrivateInfo = {setPrivateInfo}
                        certificateLevelList = {certificateLevelList}
                        certificateLevelSelected = {certificateLevelSelected}
                        setCertiificationLevelSelected = {setCertiificationLevelSelected}
                        />
                </div>
                <div className='flex-1'>
                    <FormPrivateInfoContact
                        privateInfo = {privateInfo}
                        setPrivateInfo = {setPrivateInfo}
                        bankAccountList = {bankAccountList}
                        bankAccountSelected = {bankAccountSelected}
                        setBankAccountSelected = {setBankAccountSelected}
                        languagesList = {languagesList}
                        languagesListSelected = {languagesListSelected}
                        setLanguagesListSelected = {setLanguagesListSelected}
                        />
                    <FormPrivateInfoEmergency
                        privateInfo = {privateInfo}
                        setPrivateInfo = {setPrivateInfo}
                        />
                    <FormPrivateInfoWorkPermit
                        privateInfo = {privateInfo}
                        setPrivateInfo = {setPrivateInfo}
                        handlePermitFileUpload={handlePermitFileUpload}
                        loadingPermitFile ={loadingPermitFile}
                        selectedPermit = {selectedPermit}
                    />
                </div>
            </div>
        </div>
    )
}

export default FormPrivateInfo