import React, { useState,useEffect } from 'react'
import CardHeaderWithActions from '../../components/ui/CardHeaderWithActions'
import ListEmployees from '../../components/employee/ListEmployees'
import { useCurrent, usePost } from '../../hooks';
import {  NoDataFound, MwSpinner, MwButton } from '../../components/ui';
import ListDepartments from '../../components/employee/ListDepartments';
import { Link, useParams } from 'react-router-dom';

const Employees = () => {
    const params = useParams();
    const [selectedDepartment,setSelectedDepartment] = useState(0);
    const {currentTenantId,currentCompanyId,currentBranchId,userId,currentLangId} = useCurrent();
    const [employeesList,setEmployeesList] = useState([]);

    const {data:employeesData,loading:loading,postData:getEmployees} = usePost();
    const {data:empByDept,loading:loadingEmpByDept,postData:getEmpByDept} = usePost();
    const {data:departmentsData,loading:loadingDepartments,postData:getDepartments} = usePost();

    const _data = {
        tenant_id:currentTenantId,
        company_id:currentCompanyId,
        branch_id:currentBranchId
    }

    const _init = async() => {
        if(currentTenantId && currentCompanyId && currentBranchId){
            if(!employeesData){
                await fetchEmployees();
            }
            if(!departmentsData){
                await fetchDepartments();
            }
        }
    }


    const fetchEmpByDept = async (departmentId) => {
        const _data = {
            tenant_id:currentTenantId,
            company_id:currentCompanyId,
            branch_id:currentBranchId,
            department_id:departmentId
        }
        await getEmpByDept(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/employees/dept`,_data)
    }

    const fetchEmployees = async() =>{
        await getEmployees(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/employees`,_data);
    }

    const fetchDepartments = async() =>{
        await getDepartments(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/departments`,_data);
    }

    useEffect(() =>{
        if(employeesData){
            console.log(employeesData);
            setEmployeesList(employeesData.data);
        }
    },[employeesData]);

    useEffect(() =>{
        if(empByDept){
            console.log(empByDept);
            setEmployeesList(empByDept.data);
        }
    },[empByDept]);

    useEffect(() =>{
        if(departmentsData){
            console.log(departmentsData);
        }
    },[departmentsData]);

    useEffect(() =>{
        _init();
    },[]);

    const onDepartmentChange = async(departmentId) =>{
        console.log("onDepartmentChange",departmentId);
        setSelectedDepartment(departmentId);
        await fetchEmpByDept(departmentId)
    }

    return (
        <div>
            <CardHeaderWithActions title={`إدارة الموظفين`}>
                <div className='flex '>
                </div>
            </CardHeaderWithActions>
            { (loading || loadingEmpByDept) ? 
                <MwSpinner  /> :
                <div className=''>
                    { employeesData?.data?.length > 0 ? 
                    <div>
                        <div className='flex gap-5'>
                            <div className='flex-1 '>
                                <ListEmployees data={employeesList} currentLangId={currentLangId} /> 
                            </div>
                            <div className=' w-64 '>
                                {
                                    <ListDepartments 
                                        employeesCount={employeesData?.data?.length} 
                                        data={departmentsData?.data}
                                        onDepartmentChange={onDepartmentChange}
                                        selected = {selectedDepartment}
                                        /> 
                                }
                            </div>
                        </div>
                    </div>: <NoDataFound className={` rounded-xl h-full border-2 bg-slate-100 `} msg={'لا يوجد موظفين'} >
                            {/* <span> اهلا بك عميلنا العزيز في نظام شئون الموظفين  </span> */}
                        <div className='text-center text-xs text-indigo-400 p-2'>
                            <Link to={`/${params.tenant}/employees/addNewEmployee`} >انشاء موظف جديد</Link>
                        </div>
                    </NoDataFound> }
                </div>
            }
        </div>
    )
}

export default Employees