import React from 'react'
import { MwInputText,MwSelector, MwSelectorMulti, MwTextArea } from '../ui'

const JobPositionForm = ({
    errors,
    jobPositionState,
    setJobPositionState,
    departmentList,
    departmentSelected,
    setDepartmentSelected,
    locationList,
    locationSelected,
    setLocationSelected,
    setShowLocationModal,
    employeeTypesList,
    employeeTypesSelected,
    setEmployeeTypesSelected,
    employeesList,
    empRecruiterSelected,
    setEmpRecruiterSelected,
    interviewsSelected,
    setInterviewsSelected
}) => {
  return (
    <div className='text-xs rounded-xl bg-slate-200 p-3  '>
                <div className='w-full flex flex-col gap-5'>
                    <div className='flex gap-5'>
                        <div className='flex-1'>
                            <MwInputText
                                label="اسم الوظيفة"
                                value = {jobPositionState.name}
                                onChange = { (e) => setJobPositionState({ ...jobPositionState,name:e.target.value}) }
                                classNameInput = {`font-bold `}
                                invalid={!!errors?.name}
                                invalidMsg={errors?.name}
                            />
                        </div>
                        <div className='flex-1 relative'>
                            <MwSelector
                                label={`القسم`}
                                dataType='labelValue'
                                initalValue='أختار القسم...'
                                _data={departmentList}
                                selectedItem={departmentSelected}
                                setSelectedItem={setDepartmentSelected}
                                withAddNew ={false}
                                className='  '
                            />
                        </div>
                    </div>
                    <div  className='flex gap-5'>
                        <div className='flex-1 relative'>
                            <MwSelector
                                label={`مكان / موقع الوظيفة`}
                                dataType='labelValue'
                                initalValue='أختار مكان/موقع الوظيفة...'
                                _data={locationList}
                                selectedItem={locationSelected}
                                setSelectedItem={setLocationSelected}
                                // withAddNew ={false}
                                onClickNew={()=>setShowLocationModal(true)}
                            />
                        </div>
                        <div className='flex-1 '>
                            <MwSelector
                                label={`نوع التوظيف`}
                                dataType='labelValue'
                                initalValue='  أختار نوع التوظيف...'
                                _data={employeeTypesList}
                                selectedItem={employeeTypesSelected}
                                setSelectedItem={setEmployeeTypesSelected}
                                withAddNew ={false}
                            />
                        </div>
                    </div>
                    <div  className='flex gap-5'>
                        <div className='flex gap-3 flex-1'>
                            <div className='flex-1 '>
                                <MwSelector
                                    label={`مسئول التوظيف`}
                                    dataType='labelValue'
                                    initalValue='أختار مسئول التوظيف...'
                                    _data={employeesList}
                                    selectedItem={empRecruiterSelected}
                                    setSelectedItem={setEmpRecruiterSelected}
                                    withAddNew ={false}
                                />
                            </div>
                            <div className='flex-1 '>
                                <MwSelectorMulti
                                    label={`مسئولين المقابلات الشخصية`}
                                    dataType='labelValue'
                                    initalValue='أختار المسئولين عن المقابلات الشخصية ...'
                                    _data={employeesList}
                                    selectedItem={interviewsSelected}
                                    setSelectedItem={setInterviewsSelected}
                                    withAddNew ={false}
                                />
                            </div>
                        </div>
                        <div className='flex flex-1 gap-3'>
                            <div className='flex-1'>
                                <MwInputText
                                    label="العدد المتوقع"
                                    value = {jobPositionState.target}
                                    onChange = { (e) => setJobPositionState({ ...jobPositionState,target:e.target.value}) }
                                    classNameInput = {`font-bold `}
                                    invalid={!!errors?.target}
                                    invalidMsg={errors?.target}
                                />
                            </div>
                            <div className='flex-1 flex justify-start  items-center gap-2 pt-4'>
                                <input type='checkbox' value={jobPositionState.isPublished} onChange={(e) => setJobPositionState({ ...jobPositionState,isPublished:e.target.value})} />
                                <label htmlFor="">نشرها علي الموقع الالكتروني</label>
                            </div>
                        </div>
                    </div>
                    <div className='flex-1'>
                        <MwTextArea
                            label="وصف الوظيفة"
                            value = {jobPositionState.jobSummary}
                            onChange = { (e) => setJobPositionState({ ...jobPositionState,jobSummary:e.target.value}) }
                            classNameInput = {`font-bold `}
                            invalid={!!errors?.jobSummary}
                            invalidMsg={errors?.jobSummary}
                        />
                    </div>
                </div>
            </div>
  )
}

export default JobPositionForm