import React, { useEffect, useState } from 'react'
import CardHeaderWithActions from '../../components/ui/CardHeaderWithActions'
import { MwButton } from '../../components/ui'
import AddNewResumeLine from '../../components/resume-lines/AddNewResumeLine';
import ResumeLinesList from '../../components/resume-lines/ResumeLinesList';
import { useCurrent } from '../../hooks';

const ResumeLines = ({onHide=()=>{},callback,resumeLinesList,setResumeLinesList}) => {
    const {currentTenantId,currentCompanyId,currentBranchId,currentLangId,userId} = useCurrent();
    const [mode,setMode] = useState('Adding');
    const [itemSelected,setItemSelected] = useState();
    const [errors,setErrors] = useState();

    const [typeSelected,setTypeSelected] = useState({});
    const [formState,setFormState] = useState({
        title:'',
        type:'',
        dateStart: '',
        dateEnd: '',
        description:'',
        createdBy:'',
        tenantId:'',
        companyId:'',
    })
    const saveHandler = async() => {
        setErrors({})
        let _errors = null; 
        if(!formState.title) {
            _errors ={..._errors,title:'يجب ادخال اسم التدريب/الخبرة '};       
        }
        if(!typeSelected.value) {
            _errors = {..._errors,typeSelected:'يجب اختيار النوع'};
        }
        if(!formState.dateStart) {
            _errors ={..._errors,dateStart:'يجب ادخال تاريخ البداية '};       
        }
        if(!formState.dateEnd) {
            _errors ={..._errors,dateEnd:'يجب ادخال تاريخ البداية '};       
        }
        if(_errors){
            setErrors(_errors);
            return;
        }
        if(mode === 'Editing'){
            resumeLinesList[itemSelected?.index] = {
                title:formState.title,
                type:typeSelected.value,
                dateStart: formState.dateStart,
                dateEnd: formState.dateEnd,
                description:formState.description,
                createdBy:userId,
                tenantId:currentTenantId,
                companyId:currentCompanyId, 
            };
            setResumeLinesList([...resumeLinesList])
        }else{
            setResumeLinesList([...resumeLinesList,{
                title:formState.title,
                type:typeSelected.value,
                dateStart: formState.dateStart,
                dateEnd: formState.dateEnd,
                description:formState.description,
                createdBy:userId,
                tenantId:currentTenantId,
                companyId:currentCompanyId, 
            }])
        }
        console.log(resumeLinesList)
    }

    const deleteHandler = (index) =>{
        const filterList =  resumeLinesList.filter((item,i) => i !== index )
        setResumeLinesList(filterList)
    }

    useEffect(() => {
        if(mode === 'Editing'){
            console.log('Editing',itemSelected)
            setFormState(itemSelected)
            setTypeSelected({label:itemSelected.type,value:itemSelected.type,lang:'en'})
        }
    },[mode,itemSelected])

    return (
        <div className='flex flex-col  p-3'>
            <CardHeaderWithActions title={`ادارة الدورات التدريبية والخبرات`}>
                <MwButton type='cancelBtn' inGroup={true} onClick={()=>onHide()}>إغلاق</MwButton>
                <MwButton type='saveBtn' inGroup={true} onClick={saveHandler}>حفظ</MwButton>
            </CardHeaderWithActions>

            <AddNewResumeLine
                formState = {formState}
                setFormState = {setFormState}
                typeSelected = {typeSelected}
                setTypeSelected = {setTypeSelected}
                errors = {errors}
                />
            <ResumeLinesList
                data = {resumeLinesList}
                deleteHandler = {deleteHandler}
                setMode = {setMode}
                setItemSelected={setItemSelected}
                />
        </div>
    )
}

export default ResumeLines