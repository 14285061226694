import React,{useState,useEffect} from 'react'
import ListJobPosition from '../components/job-position/ListJobPosition'
import { useCurrent,useDelete,useFetch,usePost, usePut } from '../hooks';
import { ConfirmDelete, SideModal } from '../components/ui';
import Locations from './Locations';
import JobPositionForm from '../components/job-position/JobPositionForm';
import CardHeaderWithActions from '../components/ui/CardHeaderWithActions'
import { MwButton, MwSpinnerButton } from '../components/ui'
import AlertMsg from '../components/ui/AlertMsg';

const JobPosition = ({onHide,callbackSave}) => {
    const [selectedItem,setSelectedItem] = useState();
    const [mode,setMode] = useState('Adding');
    const [showConfirmDelete,setShowConfirmDelete] = useState(false);
    const [showLocationModal,setShowLocationModal] = useState(false);
    const [jobPositionState,setJobPositionState] = useState({
        name:'',
        target:'',
        isPublished: false,
        jobSummary:'',
    });
    const [departmentSelected, setDepartmentSelected] = useState({});
    const [locationSelected, setLocationSelected] = useState({});
    const [employeeTypesSelected, setEmployeeTypesSelected] = useState({});
    const [empRecruiterSelected, setEmpRecruiterSelected] = useState({});
    const [interviewsSelected, setInterviewsSelected] = useState([]);

    const [departmentList, setDepartmentList] = useState([]);
    const [employeesList, setEmployeesList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [employeeTypesList,setEmployeeTypesList] = useState([]);
    const [errors,setErrors] = useState({});
    const {currentTenantId,currentCompanyId,currentBranchId,currentLangId,userId} = useCurrent();
    
    const {data:resultDepartmentsData,loading:loadingDepartments,postData:postDataDepartments} = usePost();
    const {data:resultEmployeesData,loading:loadingEmployees,postData:postDataEmployees} = usePost();
    const {data:resultEmployeeTypesData,loading:loadingEmployeeTypes,postData:postDataEmployeeTypes} = useFetch(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/employee-types`);
    const {data:resultPost,loading:loadingPost,postData} = usePost();
    const {data:resultPut,loading:loadingPut,putData} = usePut();
    const {data:jobPositionData,loading:loadingJobPosition,postData:getJobPosition} = usePost();
    const {data:resultLocationList,loading:loadingLocationList,postData:getLocations} = usePost();
    
    const {data:resultDelete,loading:loadingDelete,deleteItem} = useDelete();

    const _data = {
        tenant_id:currentTenantId,
        company_id:currentCompanyId,
        branch_id:currentBranchId
    }
    const init = async() => {
        if(currentTenantId && currentCompanyId && currentBranchId){
            if(!loadingEmployees && !resultEmployeesData){
                await postDataEmployees(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/employees`,_data);
            }
            if(!loadingDepartments && !resultDepartmentsData){
                await postDataDepartments(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/departments`,_data);
            }
            //if(!jobPositionData){
                getJobPosition(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/job-positions`,_data);
            //}
            getAllLocations();
        }
    }
    
    const getAllLocations = async() => {
        await getLocations(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/locations`,_data);

    }

    useEffect(() =>{
        init();
    },[resultPost,resultDelete,resultPut]);


    useEffect(() =>{
        if(resultDepartmentsData){
            const deptList = resultDepartmentsData.data.map(dept => ({
                label:`${dept.parent_relation?.parent_relation?.parent_relation?.name ? dept.parent_relation?.parent_relation?.parent_relation?.name+'/':'' } ${dept.parent_relation?.parent_relation?.name ? dept.parent_relation?.parent_relation?.name+'/' : ''} ${dept.parent_relation?.name || 'قسم رئيسي'}/${dept.name}`,
                value:dept.id,
                lang: currentLangId
                
            }));
            setDepartmentList(deptList);
        }
    },[resultDepartmentsData]);

    useEffect(() =>{
        if(resultEmployeesData){
            const empList = resultEmployeesData.data.map(employee => ({
                label:employee[`name_${currentLangId}`],
                value:employee.id,
                lang: currentLangId
                
            }));
            setEmployeesList(empList);
        }
    },[resultEmployeesData]);

    useEffect(() =>{
        if(resultEmployeeTypesData){
            const empTypesList = resultEmployeeTypesData.data.map(empType => ({
                label:empType.name,
                value:empType.id,
                lang: currentLangId
                
            }));
            setEmployeeTypesList(empTypesList);
        }
    },[resultEmployeeTypesData]);

    useEffect(() => {
        if(resultPost){
            console.log(resultPost);
            restFrom()
            if(onHide){
                callbackSave();
                onHide();
            }
        }
    },[resultPost]);

    useEffect(() => {
        if(jobPositionData){
            console.log('jobPositionData =>',jobPositionData);
        }
    },[jobPositionData]);

    useEffect(() => {
        if(resultLocationList){
            console.log('resultLocationList =>',resultLocationList);
            const _list = resultLocationList.data.map(location =>({
                label: location.name,
                value: location.id ,
                lang: currentLangId
            }));
            setLocationList(_list);
        }
    },[resultLocationList]);

    const restFrom = () => {
        setJobPositionState({
            name:'',
            target:'',
            isPublished: false,
            jobSummary:'',
        })
    }
    const saveHandler = () => {
        const _data = {
            name: jobPositionState.name,
            department_id: departmentSelected.value || null,
            location_id: locationSelected.value || null,
            employee_type_id: employeeTypesSelected.value || null,
            is_published: jobPositionState.isPublished,
            target: jobPositionState.target || null,
            recruiter_id: empRecruiterSelected.value || null,
            intervieews_ids:JSON.stringify(interviewsSelected) || null,
            job_summary: jobPositionState.jobSummary || null,
            tenant_id: currentTenantId,
            company_id: currentCompanyId,
            branch_id : currentBranchId,
            created_by : userId
        };
        console.log(_data);
        if(mode === 'Adding'){
            postData(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/job-positions/add`,_data)
        }
        if(mode === 'Editing'){
            putData(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/job-positions/${selectedItem.id}`,_data)
        }
    }
    const deleteHandler = () => {
        console.log(selectedItem);
        deleteItem(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/job-positions/${selectedItem.id}`,_data)
    }
    useEffect(() =>{
        if(resultDelete){
            setShowConfirmDelete(false)
        }
    },[resultDelete])
    useEffect(() =>{
        if(mode === 'Editing'){
            console.log('Editing => ',selectedItem);
            setJobPositionState({
                name: selectedItem.name,
                target: selectedItem.target,
                isPublished: selectedItem.is_published || false,
                jobSummary: selectedItem.job_summary ,
            });
            setDepartmentSelected({
                label: selectedItem?.department?.name,
                value: selectedItem?.department?.id
            });
            setLocationSelected({
                label: selectedItem?.location?.name,
                value: selectedItem?.location?.id
            })
            setEmployeeTypesSelected({
                label: selectedItem?.employee_types?.name,
                value: selectedItem?.employee_types?.id
            })
            setEmpRecruiterSelected({
                label: selectedItem?.recruiter?.name_ar || selectedItem?.recruiter?.name_en ,
                value: selectedItem?.recruiter?.id
            })
        }
    },[mode,selectedItem])
    return (
        <>
            { resultPut && <AlertMsg > تم التعديل بنجاح </AlertMsg> }
            <SideModal onShow={showLocationModal} setOnShow={setShowLocationModal}>
                {showLocationModal ? <Locations callback={getAllLocations}  /> : <></>}
            </SideModal>
            <ConfirmDelete
                title={'حذف الوظيفة'}
                msg={'هل انت متاكد من حذف الوظيفة'}
                onShow={showConfirmDelete}
                setOnShow={setShowConfirmDelete}
                loading={loadingDelete}
                onDeleteHandler={deleteHandler}
                />
            <div className='flex flex-col gap-5'>
            <div className='p-3'>
                <CardHeaderWithActions title={`إدارة المسميات الوظيفية`}>
                    { !loadingPost && onHide && <MwButton type='cancelBtn' inGroup={true} onClick={()=>onHide()}>إغلاق</MwButton> }
                    { loadingPost ? <MwSpinnerButton/> : <MwButton type='saveBtn' inGroup={true} onClick={saveHandler}>حفظ</MwButton> }
                </CardHeaderWithActions>
                <div className='text-sm font-bold text-slate-500 p-2'>
                    <span> { mode === 'Adding' ? 'أضف وظيفة جديدة ' : 'تعديل وظيفة' } </span>
                </div>
                <JobPositionForm
                    errors = {errors}
                    jobPositionState = {jobPositionState}
                    setJobPositionState = { setJobPositionState }
                    departmentList = {departmentList}
                    departmentSelected = {departmentSelected}
                    setDepartmentSelected = {setDepartmentSelected}
                    locationList = {locationList}
                    locationSelected = {locationSelected}
                    setLocationSelected = {setLocationSelected}
                    setShowLocationModal = {setShowLocationModal}
                    employeeTypesList = {employeeTypesList}
                    employeeTypesSelected   = {employeeTypesSelected}
                    setEmployeeTypesSelected = {setEmployeeTypesSelected}
                    employeesList = {employeesList}
                    empRecruiterSelected    = {empRecruiterSelected}
                    setEmpRecruiterSelected = {setEmpRecruiterSelected}
                    interviewsSelected  = {interviewsSelected}
                    setInterviewsSelected = {setInterviewsSelected}
                    />
                </div>
                { loadingJobPosition ? <MwSpinnerButton withLabel={false} isFullCenter={true} /> : <ListJobPosition data={jobPositionData} setShowConfirmDelete={setShowConfirmDelete} setSelectedItem={setSelectedItem} mode={mode} setMode={setMode} /> }
            </div>
        </>
    )
}

export default JobPosition