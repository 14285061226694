import React from 'react'
import { MwInputText } from '../ui'

const AddNewWorkingHours = ({workingHourState,setWorkingHourState,errors}) => {
    return (
        <>
            <div className='bg-slate-50 rounded-lg text-sm p-2'>
            <div className='text-sm font-bold text-slate-500 p-2'>
                <span> أضف دوام جديد </span>
            </div>
                <div className='flex-1'>
                    <MwInputText
                        label="اسم الدوام "
                        value = {workingHourState.label}
                        onChange = { (e) => setWorkingHourState({ ...workingHourState,label:e.target.value}) }
                        classNameInput = {`font-bold `}
                        invalid={!!errors?.label}
                        invalidMsg={errors?.label}
                    />
                </div>
                <div className='flex gap-3'>
                    <div className='flex-1'>
                        <MwInputText
                            inputType="time"
                            label="بداية الدوام "
                            value = {workingHourState.startTime}
                            onChange = { (e) => setWorkingHourState({ ...workingHourState,startTime:e.target.value}) }
                            classNameInput = {`font-bold `}
                            invalid={!!errors?.startTime}
                            invalidMsg={errors?.startTime}
                        />
                    </div>
                    <div className='flex-1'>
                        <MwInputText
                            inputType="time"
                            label="نهاية الدوام "
                            value = {workingHourState.endTime}
                            onChange = { (e) => setWorkingHourState({ ...workingHourState,endTime:e.target.value}) }
                            classNameInput = {`font-bold `}
                            invalid={!!errors?.endTime}
                            invalidMsg={errors?.endTime}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddNewWorkingHours