import React, { useEffect, useState } from 'react'
import { MwButton , MwSpinnerButton} from '../ui'
import CardHeaderWithActions from '../ui/CardHeaderWithActions'
import { useCurrent, usePost, usePut } from '../../hooks';
import DepartmentForm from './DepartmentForm';

const EditDepartment = ({item,onHide,callback,setReload,setMode}) => {
    

    const [errors,setErrors] = useState({});
    const [saved,setSaved] = useState(false);
    const [departmentName, setDepartmentName] = useState();
    const [managerSelected, setManagerSelected] = useState();
    const [parentDepartmentSelected, setParentDepartmentSelected] = useState();
    const [employeesList, setEmployeesList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const {currentTenantId,currentCompanyId,currentBranchId,currentLangId,userId} = useCurrent();
    const {data:resultEmployeesData,loading:loadingEmployees,postData:postDataEmployees} = usePost();
    const {data:resultDepartmentsData,loading:loadingDepartments,postData:postDataDepartments} = usePost();
    const {data:resultPut,loading:loadingPut,putData} = usePut();


    

    const init = async() => {
        setDepartmentName(item.name)
        if(item.manager_relation){
            setManagerSelected({
                label: item.manager_relation[`name_${currentLangId}`],
                value: item.manager_relation.id,
                lang: currentLangId
            })
        }
        if(item.parent_relation){
            
            setParentDepartmentSelected({
                label: item.parent_relation.name,
                value: item.parent_relation.id,
                lang: currentLangId
            })
            console.log(parentDepartmentSelected)
        }

        const _data = {
            tenant_id:currentTenantId,
            company_id:currentCompanyId,
            branch_id:currentBranchId
        }
        if(currentTenantId && currentCompanyId && currentBranchId){
            if(!loadingEmployees && !resultEmployeesData){
                await postDataEmployees(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/employees`,_data);
            }
            if(!loadingDepartments && !resultDepartmentsData){
                await postDataDepartments(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/departments`,_data);
            }
        }
    }

    useEffect(() =>{
        init();
        console.log(managerSelected)
    },[item]);

    useEffect(() =>{
        if(resultEmployeesData){
            const empList = resultEmployeesData.data.map(employee => ({
                label:employee[`name_${currentLangId}`],
                value:employee.id,
                lang: currentLangId
                
            }));
            setEmployeesList(empList);
        }
    },[resultEmployeesData]);

    useEffect(() =>{
        if(resultDepartmentsData){
            const deptList = resultDepartmentsData.data.map(dept => ({
                label:dept.name,
                value:dept.id,
                lang: currentLangId
                
            }));
            setDepartmentList(deptList);
        }
    },[resultDepartmentsData]);

    useEffect(() => {
        if(resultPut){
            console.log(resultPut);
            setReload(true)
            if(onHide){
                setSaved(true);
                onHide();
                callback();
            }
            setDepartmentName('')
            setMode('Adding')

        }
    },[resultPut]);

    const saveHandler = async() =>{
        setErrors({});
        if(departmentName){
            const _data = {
                name: departmentName,
                manager_id:managerSelected.value || null,
                parent_id: parentDepartmentSelected.value || null,
                created_by:userId,
                tenant_id:currentTenantId,
                company_id:currentCompanyId,
                branch_id:currentBranchId
            }
            await putData(`${process.env.REACT_APP_HR_EMPLYEE_API_BASE_URL}/departments/${item.id}`,_data);
            
        }else{
            setErrors({ departmentName:'يجب ادخال اسم القسم' });
        }
    }

    return (
        <div className='flex flex-col   p-4 '>

            <CardHeaderWithActions>
                { !loadingPut && onHide && <MwButton type='cancelBtn' inGroup={true} onClick={()=>onHide()}>إغلاق</MwButton> }
                { loadingPut ? <MwSpinnerButton/> : <MwButton type='saveBtn' disabled={saved} inGroup={true} onClick={saveHandler}>حفظ</MwButton>}
            </CardHeaderWithActions>

            <div className='text-sm font-bold text-slate-500 p-2'>
                <span> أضف قسم جديد </span>
            </div>

            <DepartmentForm
                errors = {errors}
                departmentName = {departmentName}
                setDepartmentName ={setDepartmentName}
                employeesList = {employeesList}
                managerSelected = {managerSelected}
                setManagerSelected = {setManagerSelected}
                departmentList = {departmentList}
                parentDepartmentSelected = {parentDepartmentSelected}
                setParentDepartmentSelected = {setParentDepartmentSelected}
                />
            
        </div>
    )
}

export default EditDepartment