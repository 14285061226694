import React, { useState } from 'react'
import { MwButton, MwInputText } from '../ui'
import { RiDeleteBin5Line } from "react-icons/ri";

const AddNewSkill = ({skillTypeState,setSkillTypeState}) => {
    const [skillTitle,setSkillTitle] = useState('')
    const [skillList,setSkillList] = useState([]);
    const [errors,setErrors] = useState();

    const saveSkillHandler = () => {
        if(!skillTitle){
            setErrors({title:'يجب ادخال المهارة'})
            return;
        }
        setSkillList([...skillList,skillTitle]);
        setSkillTitle('')
        setSkillTypeState({...skillTypeState,skills:[...skillList,skillTitle]})
        console.log(skillList);
    }

    const deleteHandler = (index) => {
        const _filter = skillList.filter((item,i) => i !== index);
        setSkillList(_filter)
        setSkillTypeState({...skillTypeState,skills:[..._filter]})
    };

    return (
        <div className='flex flex-col'>
            <div className='flex items-end py-3 gap-3'>
                <div className='w-full'>
                    <MwInputText
                        label="المهارة"
                        placeholder="e.g.English"
                        value = {skillTitle}
                        onChange = { (e) => setSkillTitle(e.target.value) }
                        classNameInput = {`font-bold border border-slate-500`}
                        invalid={!!errors?.title}
                        // invalidMsg={errors?.title}
                    />
                </div>
                <MwButton type='secondary-reverse'  inGroup={true} onClick={saveSkillHandler}>اضف</MwButton>
            </div>
            <div>
                {
                    skillList.map((skill,index) =>(
                    <div key={index} className='flex justify-between items-center text-slate-500 mx-3 text-sm border-b p-1'>
                        <div className='w-16 text-center'>{index+1}</div>
                        <div className='flex-1'>{skill}</div>
                        <div onClick={()=>deleteHandler(index)}><RiDeleteBin5Line/></div>
                    </div>
                    ))
                }
            </div>

        </div>
    )
}

export default AddNewSkill