import React from 'react'
import MwSpinnerButton from './MwSpinnerButton'

const MwInputFile = ({onChangeHandler=()=>{} ,loading=false, acceptTypes="image/*",label,id='file_input'}) => {
    return (
        <div className='flex  items-center justify-between  p-2'>
            {label && <label className=" text-sm font-medium text-gray-900 dark:text-white" htmlFor={id}>{label}</label>}
            <input type="file" 
                id={id}
                className="flex-1  text-gray-800 text-sm 
                file:mx-6 file:px-4 file:py-2 file:text-sm file:border-0 
                file:rounded-full file:font-semibold file:text-violet-700 file:bg-violet-50 
                hover:file:bg-amber-100 hover:file:cursor-pointer" 
                accept={acceptTypes} 
                onChange={onChangeHandler}  
                />
            { loading && <MwSpinnerButton withLabel={false}/>}
        </div>
    )
}

export default MwInputFile