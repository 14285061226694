import React from 'react'
import {  MwInputText,MwSelector} from '../ui'

const DepartmentForm = ({
    errors,
    departmentName,
    setDepartmentName,
    employeesList,
    managerSelected,
    setManagerSelected,
    departmentList,
    parentDepartmentSelected,
    setParentDepartmentSelected
}) => {
return (
    <div className='text-xs rounded-xl bg-slate-200 p-3  '>
                <div className='w-full flex flex-col gap-5'>
                    <div className='flex-1'>
                        <MwInputText
                            label="اسم القسم"
                            value = {departmentName}
                            onChange = { (e) => setDepartmentName(e.target.value) }
                            classNameInput = {`font-bold `}
                            invalid={!!errors.departmentName}
                            invalidMsg={errors.departmentName}
                        />
                    </div>
                    <div className='flex gap-5 pb-3'>
                        <div className='flex-1 relative'>
                            <MwSelector
                                label={`مدير القسم`}
                                dataType='labelValue'
                                initalValue='أختار المدير المسئول...'
                                _data={employeesList}
                                selectedItem={managerSelected}
                                setSelectedItem={setManagerSelected}
                                withAddNew ={false}
                            />
                        </div>
                        <div className='flex-1 relative'>
                            <MwSelector
                                label={`القسم الرئيسي`}
                                dataType='labelValue'
                                initalValue='أختار القسم الرئيسي...'
                                _data={departmentList}
                                selectedItem={parentDepartmentSelected}
                                setSelectedItem={setParentDepartmentSelected}
                                withAddNew ={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
  )
}

export default DepartmentForm